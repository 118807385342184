let intial = () => {
  console.log("No functions found")
}

let closeJobs = intial

export const updateCloseJobs = (funct) => {
  closeJobs = funct
}

export const runCloseJobs = () => {
  closeJobs();
  closeJobs = intial
}
