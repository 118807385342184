import React, { Fragment } from 'react';
import { logout, changePassword } from '../../../api/user';
import ChangePassword from './ChangePassword';
import { Link } from 'react-router-dom';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: {
                name: "",
                email: "",
            },
            passwordDetails: {
                password: "",
                newpassword: "",
                confirmpassword: "",
            },
            alertMessage: "",
            alertColor: "",
            alertShow: false,
            disabled: false,
        }
    }

    componentDidMount = () => {
        let userDetails = JSON.parse(localStorage.getItem('schedulingApp'))
        this.setState({
            userDetails,
        })
    }

    setOldPassword = (pw) => {
        let passwordDetails = this.state.passwordDetails
        passwordDetails.password = pw
        this.setState({
            passwordDetails,
        })
    }

    setNewPassword = (pw) => {
        let passwordDetails = this.state.passwordDetails
        passwordDetails.newpassword = pw
        this.setState({
            passwordDetails,
        })
    }

    setConfirmedPassword = (pw) => {
        let passwordDetails = this.state.passwordDetails
        passwordDetails.confirmpassword = pw
        this.setState({
            passwordDetails,
        })
    }

    logoutUser = () => {
        logout().then((response) => {
            localStorage.setItem("schedulingApp", JSON.stringify({}))
            this.props.history.push('/login');
        })
    }

    changePasswordModalToggle = (value) => {
        this.setState({
            changePasswordModal: value,
        })
    }

    changePassword = (e) => {
        e.preventDefault();
        if (this.state.passwordDetails.newpassword != this.state.passwordDetails.confirmpassword) {
            this.setAlert("Password confirmation doesn't match password", "danger")
        } else {
            this.setState({
                disabled: true,
            }, () => {
                changePassword(this.state.passwordDetails).then((response) => {
                    this.setAlert("Password changed successfully", "success");
                    this.setState({
                        passwordDetails: {
                            password: "",
                            newpassword: "",
                            confirmpassword: "",
                        },
                        disabled: false,
                    })
                }).catch((error) => {
                    this.setAlert(error.body.message, "danger")
                    this.setState({
                        disabled: false,
                    })
                })
            })
        }

    }

    setAlert = (message, color) => {
        this.setState({
            alertMessage: message,
            alertColor: color,
            alertShow: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertMessage: "",
                    alertColor: "",
                    alertShow: false,
                })
                if (color === "success") {
                    this.changePasswordModalToggle(false)
                }
            }, 1500)
        })
    }

    render() {
        return (
            <div>
                <div className="header">
                    <div className="logo d-flex">
                        <img src='/media/SEM_Group_logo.gif' alt="Logo" />
                        <p className="mt-auto mb-0 pl-2" style={{ fontSize: "13px" }}>version 1.0</p>
                    </div>

                    <div className="right-block justify-content-end d-flex align-items-center">
                        <ul className="d-flex align-self-center">
                            {
                                !window.location.href.includes("home") && <Link to="/home">Back to home</Link>
                            }
                            <li className="menu" onClick={this.props.sideMenuToggle}></li>
                        </ul>
                    </div>


                </div>

                <div className="side-menu">
                    <h1>
                        {this.state.userDetails.name}
                        <span>
                            {this.state.userDetails.email}
                        </span>
                    </h1>
                    <ul>
                        {

                            this.state.userDetails.role == "administrator" &&
                            <>
                                <li>
                                    <Link to="/user">User Management</Link>
                                </li>
                                <li>
                                    <Link to="/master-data">Master Data</Link>
                                </li>
                            </>
                        }
                        <li>
                            <a onClick={() => { this.changePasswordModalToggle(true) }} style={{ cursor: "pointer" }}>Change Password</a>
                        </li>
                        <li>
                            <a onClick={this.logoutUser} style={{ cursor: "pointer" }}>Logout</a>
                        </li>
                    </ul>
                    <ChangePassword disabled={this.state.disabled} changePasswordModal={this.state.changePasswordModal} changePasswordModalToggle={this.changePasswordModalToggle} setOldPassword={this.setOldPassword} setNewPassword={this.setNewPassword} setConfirmedPassword={this.setConfirmedPassword} changePassword={this.changePassword} alertMessage={this.state.alertMessage} alertColor={this.state.alertColor} alertShow={this.state.alertShow} setAlert={this.setAlert} />
                </div>
            </div>
        )
    }
}

export default Navbar;