import React from 'react';
import './index.css';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import ColorCodes from './ColorCodes';
import Crew from './Crew';

class MasterData extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row w-100 master-data">
                <div className="col-6">
                    <div className="data-management">
                        <ColorCodes />
                    </div>
                </div >
                <div className="col-6">
                    <div className="data-management">
                        <Crew />
                    </div>
                </div>
            </div>
        )
    }
}

export default MasterData;