import React, { Component } from 'react';
import { Alert, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button } from 'reactstrap';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.changePasswordModal} toggle={() => { this.props.changePasswordModalToggle(false) }}>
                <ModalHeader toggle={() => { this.props.changePasswordModalToggle(false) }}>Enter Details</ModalHeader>
                <Form onSubmit={this.props.changePassword}>
                    <ModalBody>
                        <Alert color={this.props.alertColor} isOpen={this.props.alertShow}>{this.props.alertMessage}</Alert>
                        <FormGroup>
                            <Label for="oldPassword">Old password</Label>
                            <Input type="password" onChange={(event) => { this.props.setOldPassword(event.target.value) }} name="oldPassword" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="newPassword">New password</Label>
                            <Input type="password" onChange={(event) => { this.props.setNewPassword(event.target.value) }} name="newPassword" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="confirmPassword">Confirm password</Label>
                            <Input type="password" onChange={(event) => { this.props.setConfirmedPassword(event.target.value) }} name="confirmPassword" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={this.props.disabled}>Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

export default ChangePassword;