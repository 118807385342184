import axios from 'axios';
// import Promise from 'bluebird';
import moment from 'moment-timezone';
import {
  API_ROOT
} from '../config';

export default function fetch(options) {
  return new Promise((resolve, reject) => {
    let header = {};
    header['Content-Type'] = 'application/json';
    header['Timezone'] = moment.tz.guess();
    axios({
      url: API_ROOT + options.url,
      method: options.method,
      params: options.params,
      headers: header,
      data: options.body,
      withCredentials: true,
    }).then((response) => {
      resolve(response.data)
    }).catch((e) => {
      let error = {
        status: "",
        body: {},
        exp: {}
      }
      error.status = e.response.status;
      if (e.response.status === 401) {
        localStorage.setItem("schedulingApp", JSON.stringify({}))
        window.location.href = "/login";
      } else {
        error.status = e.response.status;
        if (e.response.data) {
          error.body = e.response.data;
          reject(error);
        } else {
          error.exp = e;
          reject(error);
        }

      }
    })
  })
}
