import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DeleteJob extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Remove Job From Schedule</ModalHeader>
                <ModalBody>
                    Do you want to remove all jobs with case number {this.props.job.caseNumber} or this individual job from schedule?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        this.props.action(true);
                    }}>Remove all jobs</Button>
                    <Button color="primary" onClick={() => {
                        this.props.action(false);
                    }}>Remove one job</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteJob;