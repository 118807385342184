import React from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment-timezone'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Spinner } from 'reactstrap';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import ScheduleJob from './ScheduleJob'
import { rescheduleJob, editCustomJob } from '../../../../api/jobs';
import { Alert } from 'reactstrap';
import JobDetails from './JobDetails';
import Agenda from './Agenda';
import DayView from './DayView';
import ConfirmationModal from '../../../commonComponents/ConfirmationModal';
import { listStatusColors, listJobColors } from '../../../../api/masterData';
import DayDetails from './DayDetails';
import './index.css'

const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

class Cal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            changeScheduleConfirmModal: false,
            isRescheduleButton: false,                  //used as a flag to disable the ok button in ConfirmationModal component on click
            rescheduleJob: {
                event: {},
                start: "",
                end: "",
                crew: "",
            },
            alertShow: false,
            alertMessage: "",
            alertColor: "",
            statusColors: [],
            modalIsOpen: false,
            jobColors: [],
            dayDetailsShow: false,
            dateSelected: new Date(),
            eventsOnDate: [],
        }
        this.tz = moment.tz.guess();
    }

    componentDidMount = () => {
        listStatusColors().then((response) => {
            this.setState({
                statusColors: response,
            })
        })
        listJobColors().then((response) => {
            this.setState({
                jobColors: response,
            })
        })
    }

    refreshDayList = () => {
        let date = this.state.dateSelected;
        let eventsOnDate = this.props.events.filter((event) => moment(event.start).isSameOrBefore(moment(date), 'day') && moment(date).isSameOrBefore(moment(event.end), 'day'));
        this.setState({
            eventsOnDate,
        })
    }

    changeScheduleConfirmModalToggle = () => {
        this.setState((prevState) => ({
            changeScheduleConfirmModal: !prevState.changeScheduleConfirmModal,
        }))
    }

    //function to negate the value of isRescheduleButton on call
    toggleIsRescheduleButton = () => {
        this.setState((prevState) => ({
            isRescheduleButton: !prevState.isRescheduleButton,
        }))
    }

    changeScheduleConfirmModalConfirm = () => {
        this.toggleIsRescheduleButton()
        if (this.state.rescheduleJob.event.jobNumber != 0) {
            let body = {
                semJNumber: this.state.rescheduleJob.event.jobNumber,
                caseNumber: this.state.rescheduleJob.event.caseNumber,
                note: this.state.rescheduleJob.event.note,
                dateReceived: this.state.rescheduleJob.event.dateReceived,
                subCompletionDueDate: this.state.rescheduleJob.event.subCompletionDueDate,
                scheduledStartDate: this.state.rescheduleJob.start,
                scheduledEndDate: this.state.rescheduleJob.end,
                crewID: this.state.rescheduleJob.event.crewID,
                contract: this.state.rescheduleJob.event.contract,
            }
            rescheduleJob(this.state.rescheduleJob.event.id, body).then((response) => {
                this.props.monthChange(this.props.monthSelected);
                this.setAlert("Successfully rescheduled the job with job number " + this.state.rescheduleJob.event.jobNumber + " [ " + this.state.rescheduleJob.event.contract + " ]")
                this.toggleIsRescheduleButton()
            }).catch((error) => {
                this.setState({
                    changeScheduleConfirmModal: false,
                })
                this.setAlert("Error while rescheduling the job", "danger")
                this.toggleIsRescheduleButton()
            })
        } else if (this.state.rescheduleJob.event.isCustomJob) {
            let body = {
                foreman: JSON.stringify({
                    foreman: this.state.rescheduleJob.event.foreman,
                    id: this.state.rescheduleJob.event.formanID,
                }),
                note: this.state.rescheduleJob.event.note,
                jobTitle: this.state.rescheduleJob.event.name,
                crewID: this.state.rescheduleJob.event.crewID,
                scheduledStartDate: this.state.rescheduleJob.start,
                scheduledEndDate: this.state.rescheduleJob.end,
            }
            editCustomJob(this.state.rescheduleJob.event.id, body).then((response) => {
                this.props.monthChange(this.props.monthSelected);
                this.setAlert("Successfully rescheduled the job " + this.state.rescheduleJob.event.name)
                this.toggleIsRescheduleButton()
            }).catch((error) => {
                this.setState({
                    changeScheduleConfirmModal: false,
                })
                this.setAlert("Error while rescheduling the job", "danger")
                this.toggleIsRescheduleButton()
            })
        }
        this.changeScheduleConfirmModalToggle();
    }

    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertShow: true,
            alertMessage,
            alertColor,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertColor: "",
                    alertShow: false,
                    alertMessage: "",
                })
            }, 3000)
        })
    }

    moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        let role = JSON.parse(localStorage.getItem("schedulingApp")).role;
        if (role === "administrator" || role === "supervisor") {
            if (moment(event.subCompletionDueDate).isSameOrAfter(moment(end), 'day') && moment(end).isSameOrAfter(moment(start), 'day') && moment(start).isSameOrAfter(moment(event.dateReceived), 'day') || (event.isCustomJob)) {
                let rescheduleJob = {
                    event,
                    end: moment(end).utc().format(),
                    start: moment(start).utc().format(),
                }
                this.setState({
                    rescheduleJob,
                })
                this.changeScheduleConfirmModalToggle();
            } else {
                this.setAlert("Job should be within limits", "danger")
            }
        } else {
            this.setAlert("This user lacks rescheduling permissions", "danger")
        }
    }

    resizeEvent = ({ event, start, end }) => {
        if (new moment(end).format("HH") == "00") {
            end = new moment(end).subtract(30, 'minutes')
        }
        let role = JSON.parse(localStorage.getItem("schedulingApp")).role;
        if (role === "administrator" || role === "supervisor") {
            if (moment(event.subCompletionDueDate).isSameOrAfter(moment(end), 'day') && moment(end).isSameOrAfter(moment(start), 'day') && moment(start).isSameOrAfter(moment(event.dateReceived), 'day') || (event.isCustomJob)) {
                let rescheduleJob = {
                    event,
                    end: moment(end).utc().format(),
                    start: moment(start).utc().format(),
                }
                this.setState({
                    rescheduleJob,
                })
                this.changeScheduleConfirmModalToggle();
            } else {
                this.setAlert("Job should be within limits", "danger")
            }
        } else {
            this.setAlert("This user lacks rescheduling permissions", "danger")
        }
    }

    jobClicked = (event) => {
        let jobSelected = this.props.jobs.filter(job => {
            return job.id === event.id && job.isCustomJob === event.isCustomJob
        })
        jobSelected[0].name = event.name
        this.props.jobCardClicked(jobSelected[0], true)
    }

    dropFromOutside = (event) => {
        this.dropDate = event.start
        this.closeModal();
    }

    closeModal = () => {
        this.setState((prevState) => ({
            modalIsOpen: !prevState.modalIsOpen,
        }))
    }

    dateClicked = (clickedDate) => {
        const date = clickedDate.start;
        let eventsOnDate = this.props.events.filter((event) => moment(event.start).isSameOrBefore(moment(date), 'day') && moment(date).isSameOrBefore(moment(event.end), 'day'));
        this.setState({
            eventsOnDate,
            dateSelected: date,
        })
        this.changeDayDetailsShow(true);
    }

    changeDayDetailsShow = (dayDetailsShow) => {
        this.setState({
            dayDetailsShow,
        })
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = "";
        let color = "white";
        this.state.statusColors.map((status) => {
            if (status.statusID == event.statusId) {
                backgroundColor = status.colour;
            }
        })

        var style = {
            backgroundColor: backgroundColor,
            color: color,
        };
        return {
            style: style
        };
    }

    AgendaComponentWithProps = () => {
        return <Agenda
            onSelectAgendaEvent={this.jobClicked}
            monthSelected={this.props.monthSelected}
        />
    }

    dayViewWithProps = () => {
        return <DayView />
    }



    render() {
        const formats = {
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
        }
        let rescheduleMessage = "Are you sure you want to reschedule this job?";
        if (this.state.rescheduleJob.event && this.state.rescheduleJob.event.jobNumber) {
            rescheduleMessage = "Are you sure you want to reschedule job number " + this.state.rescheduleJob.event.jobNumber + " [ " + this.state.rescheduleJob.event.contract + " ] to new dates starting from " + moment(this.state.rescheduleJob.start).format("MMM Do YYYY") + " till " + moment(this.state.rescheduleJob.end).format("MMM Do YYYY") + " ?";
        }
        if (this.state.rescheduleJob.event && this.state.rescheduleJob.event.isCustomJob && this.state.rescheduleJob.event.jobNumber == 0) {
            rescheduleMessage = "Are you sure you want to reschedule " + this.state.rescheduleJob.event.name + " to new dates starting from " + moment(this.state.rescheduleJob.start).format("MMM Do YYYY") + " till " + moment(this.state.rescheduleJob.end).format("MMM Do YYYY") + " ?";
        }
        return (
            <div className="main">
                {
                    this.props.jobDetailsView &&
                    <JobDetails jobColors={this.state.jobColors} monthSelected={this.props.monthSelected} toggleList={[]} toggle={this.props.jobDetailsHide} jobDetails={this.props.selectedJob} monthChange={this.props.monthChange} setRefreshJobList={() => { this.props.setRefreshJobList(); this.refreshDayList() }} setAlert={this.setAlert} statusColors={this.state.statusColors} dateClicked={this.dateClicked} dateSelected={this.state.dateSelected} dayDetailsShow={this.state.dayDetailsShow} />
                }
                {
                    this.props.loading &&
                    <div className="spinner-wrap">
                        <Spinner style={{ width: '5rem', height: '5rem' }} />
                    </div>
                }
                <Alert className="alert-red" color={this.state.alertColor} isOpen={this.state.alertShow}>
                    {this.state.alertMessage}
                </Alert>

                <DragAndDropCalendar
                    selectable
                    draggedJob={this.props.draggedJob}
                    setAlert={this.setAlert}
                    setRefreshJobList={this.props.setRefreshJobList}
                    monthChange={this.props.monthChange}
                    monthSelected={this.props.monthSelected}
                    statusColors={this.state.statusColors}
                    localizer={localizer}
                    events={this.props.events}
                    onEventDrop={this.moveEvent}
                    resizable
                    onEventResize={this.resizeEvent}
                    onDropFromOutside={this.dropFromOutside}
                    onSelectEvent={this.jobClicked}
                    onSelectSlot={(event) => { this.dateClicked(event) }}
                    defaultView={'day'}
                    popup={true}
                    views={{ month: true, week: true, day: DayView, agenda: Agenda }}
                    components={{ agenda: { event: this.AgendaComponentWithProps } }}
                    components={{ day: { event: this.dayViewWithProps } }}
                    onSelectAgendaEvent={this.jobClicked}
                    onNavigate={(date, view) => {
                        this.props.monthChange(moment(date).format())
                    }}
                    defaultDate={new Date(this.props.monthSelected)}
                    formats={formats}
                    eventPropGetter={this.eventStyleGetter}
                />
                <div className="color-legends d-flex align-items-center">
                    <div className="d-flex">
                        <ul>
                            <li>
                                <strong style={{ fontSize: "12px" }}>JOB TYPE:</strong>
                            </li>
                            {
                                this.state.jobColors && this.state.jobColors.length > 0 &&
                                this.state.jobColors.map((jobColor) => {
                                    return (
                                        <li key={jobColor.id}>
                                            <span className="yellow" style={{ display: "inline-block", background: jobColor.colour, width: "16px", height: "16px", verticalAlign: "text-bottom", borderRadius: "4px" }}></span> {jobColor.job}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="d-flex ml-5">
                        <ul>
                            <li>
                                <strong style={{ fontSize: "12px" }}>JOB STATUS:</strong>
                            </li>
                            {
                                this.state.statusColors && this.state.statusColors.length > 0 &&
                                this.state.statusColors.map((statusColor) => {
                                    return (
                                        <li key={statusColor.id}>
                                            <span className="yellow" style={{ display: "inline-block", background: statusColor.colour, width: "16px", height: "16px", verticalAlign: "text-bottom", borderRadius: "4px" }}></span> {statusColor.status}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div >
                <ConfirmationModal job={this.state.rescheduleJob} isModal={this.state.changeScheduleConfirmModal} toggleModal={this.changeScheduleConfirmModalToggle} action={this.changeScheduleConfirmModalConfirm} message={rescheduleMessage} headerMessage="Confirm Reschedule" isDisabled={this.state.isRescheduleButton} />
                <ScheduleJob setAlert={this.setAlert} modal={this.state.modalIsOpen} toggle={this.closeModal} jobDetails={this.props.draggedJob} startDate={this.dropDate} endDate={this.dropDate} monthChange={this.props.monthChange} outerModalToggle={null} edit={false} setRefreshJobList={this.props.setRefreshJobList} statusColors={this.state.statusColors} />
                {
                    this.state.dayDetailsShow &&
                    <DayDetails setAlert={this.setAlert} toggle={this.changeDayDetailsShow} eventsOnDate={this.state.eventsOnDate} dateSelected={this.state.dateSelected} statusColors={this.state.statusColors} jobCardClicked={this.props.jobCardClicked} />
                }
            </div >
        )
    }
}

export default Cal;