import React, { Fragment } from 'react';
import { Container, Row, Col, Spinner, Input, Label } from 'reactstrap';
import moment from 'moment-timezone';
import './index.css';
import { listJobColors, listStatusColors, getCrewList } from '../../../../api/masterData';
import JobDetails from '../Calendar/JobDetails';
import { listJobTypes } from '../../../../api/jobs';


class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobColors: [],
            statusColors: [],
            jobDetailsView: false,
            jobDetails: {
                job: {},
                placeRight: true,
            },
            alertColor: "",
            alertShow: false,
            alertMessage: "",
            jobTypes: [],
            crews: [],
            jobTypeFilter: "",
            thirdPartyFilter: "",
            scheduledFilter: "",
            crewFilter: "",
        }
    }

    componentDidMount = () => {
        this.getJobTypes();
        this.listCrew();
        let element = document.getElementById("job-list");
        element.addEventListener('scroll', (event) => { this.trackScrolling(event, element) });
        this.getColors();
    }

    jobDetailsViewChange = (jobDetailsView) => {
        this.setState({
            jobDetailsView,
        })
    }
    getColors = () => {
        listJobColors().then((response) => {
            this.setState({
                jobColors: response,
            })
        })
        listStatusColors().then((response) => {
            this.setState({
                statusColors: response,
            })
        })
    }

    jobCardClicked = (job) => {
        job.name = job.title
        this.setState({
            jobDetails: {
                job,
                placeRight: true,
            },
            jobDetailsView: true,
        })
    }

    trackScrolling = (e, element) => {
        const elmnt = e.target;
        if (elmnt.scrollHeight - elmnt.scrollTop === elmnt.clientHeight) {
            this.props.pageChange();
            element.removeEventListener('scroll', this.trackScrolling);
        }
    }

    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertShow: true,
            alertMessage,
            alertColor,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertColor: "",
                    alertShow: false,
                    alertMessage: "",
                })
            }, 3000)
        })
    }

    statusColorGetter = (statusId) => {
        let backgroundColor = "";
        this.state.statusColors.map((status) => {
            if (status.statusID == statusId) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }

    getJobTypes = () => {
        listJobTypes().then((response) => {
            this.setState({
                jobTypes: response.data,
            })
        }).catch((error) => {
            console.log("unable to list job types")
        })
    }

    listCrew = () => {
        getCrewList().then((response) => {
            this.setState({
                crews: response,
            })
        }).catch((error) => {
            console.log("Error while getting crew list ", error)
        })
    }

    chooseFilter = (key, value) => {
        this.setState({
            [key]: value,
        })
    }

    applyFilter = (job) => {
        switch (this.state.scheduledFilter) {
            case "yes":
                if (!job.scheduledStartDate)
                    return 0.5;
                break;
            case "no":
                if (job.scheduledStartDate)
                    return 0.5;
                break;
        }
        switch (this.state.thirdPartyFilter) {
            case "yes":
                if (!job.isThirdparty || job.isThirdparty === "no")
                    return 0.5;
                break;
            case "no":
                if (job.isThirdparty === "yes")
                    return 0.5;
                break;
        }
        if (this.state.jobTypeFilter != "") {
            if (!job.workOrders || job.workOrders.length === 0)
                return 0.5;
            else {
                let jobTypeMatch = false;
                job.workOrders.forEach(workOrder => {
                    if (workOrder.jobTypeCode === this.state.jobTypeFilter)
                        jobTypeMatch = true;
                });
                if (!jobTypeMatch) {
                    return 0.5;
                }
            }
        }
        if (this.state.crewFilter != "") {
            if (this.state.crewFilter != job.crewID)
                return 0.5;
        }
        return 1;
    }
    render() {
        let className = "";
        className = Object.keys(this.props.jobCards).length > 0 && this.props.jobCards[Object.keys(this.props.jobCards)[0]][0].isCustomJob ? "jobcard-list h-100" : "jobcard-list";
        return (
            <div className="main col-12" >
                {
                    Object.keys(this.props.jobCards).length > 0 && !this.props.jobCards[Object.keys(this.props.jobCards)[0]][0].isCustomJob &&
                    <div className="row filter-row pt-2">
                        <div className="col-xl-1 col-lg-12 d-flex align-items-center">
                            <h5>Filter By</h5>
                        </div>
                        <div className="col-lg-6 col-xl-2 d-flex">
                            <div className="form-group w-100 text-left">
                                <Label for="scheduled">Scheduled</Label>
                                <Input type="select" className="form-control" id="scheduled" onChange={(e) => { this.chooseFilter("scheduledFilter", e.target.value) }}>
                                    <option value="">Choose</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Input>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 d-flex">
                            <div className="form-group w-100 text-left">
                                <Label for="scheduled">Job Type</Label>
                                <Input type="select" id="scheduled" className="form-control" onChange={(e) => { this.chooseFilter("jobTypeFilter", e.target.value) }}>
                                    <option value="">Choose</option>
                                    {
                                        this.state.jobTypes.length > 0 &&
                                        this.state.jobTypes.map((jobType, index) => {
                                            return (
                                                <option key={index}>{jobType.jobTypeCode}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 d-flex">
                            <div className="form-group w-100 text-left">
                                <Label for="crew">Crew</Label>
                                <Input type="select" id="crew" className="form-control" onChange={(e) => { this.chooseFilter("crewFilter", e.target.value) }}>
                                    <option value="">Choose</option>
                                    {
                                        this.state.crews.length > 0 &&
                                        this.state.crews.map((crew, index) => {
                                            return (
                                                <option value={crew.id} key={index}>{crew.crew}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 d-flex">
                            <div className="form-group w-100 text-left">
                                <Label for="third-party">Third Party Confirmation</Label>
                                <Input type="select" id="third-party" className="form-control" onChange={(e) => { this.chooseFilter("thirdPartyFilter", e.target.value) }}>
                                    <option value="">Choose</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Input>
                            </div>

                        </div>
                    </div>
                }
                < div className={className} id="job-list">
                    {
                        this.props.jobCards && Object.keys(this.props.jobCards).length > 0 &&
                        Object.keys(this.props.jobCards).map((month, index) => {
                            return (
                                <Fragment key={index}>
                                    <h2>{month}</h2>
                                    <div className="listview row">
                                        {
                                            this.props.jobCards[month].length > 0 &&
                                            this.props.jobCards[month].map((job, index) => {
                                                if (job.isCustomJob) {
                                                    return (
                                                        <div className="list-container col-xl-3 col-md-6 my-2" key={index} onClick={() => { this.jobCardClicked(job) }}>
                                                            <div className="list-grid small-box-grid">
                                                                <div className="list-header" style={{ backgroundColor: this.statusColorGetter(job.statusID) }}>
                                                                    <h1 className="d-flex align-items-center justify-content-center">{`${job.title}`}
                                                                    </h1>
                                                                </div>
                                                                <div className="list-body small-box-body">
                                                                    <ul>
                                                                        <li>
                                                                            <span>
                                                                                Scheduled Start Date
                                                                        </span>
                                                                            {job.scheduledStartDate ? moment.utc(job.scheduledStartDate).local().format("Do MMM YYYY HH:mm") : "Not Scheduled"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Scheduled End Date
                                                                        </span>
                                                                            {job.scheduledEndDate ? moment.utc(job.scheduledEndDate).local().format("Do MMM YYYY HH:mm") : "Not Scheduled"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Crew
                                                                        </span>
                                                                            {job.crew}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Foreman
                                                                        </span>
                                                                            {job.foreman || "No foreman assigned"}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="list-container col-xl-3 col-md-6 my-2" key={job.jobNumber} onClick={() => { this.jobCardClicked(job) }}>
                                                            <div className="list-grid" style={{ opacity: this.applyFilter(job) }}>
                                                                <div className="list-header" style={{ backgroundColor: this.statusColorGetter(job.statusID) }}>
                                                                    <h1 className="d-flex align-items-center justify-content-center">{`SEM Job Number ${job.jobNumber} [ ${job.contract} ]`}
                                                                        {
                                                                            job.jobActive != "true" &&
                                                                            <span className="inactive">inactive</span>
                                                                        }
                                                                    </h1>
                                                                </div>
                                                                <div className="list-body">
                                                                    <span>Job Description</span>
                                                                    <p>
                                                                        {job.jobDescription}
                                                                    </p>
                                                                    <span>Address</span>
                                                                    <p>{job.streetNumber}, {job.streetName}, {job.suburb}</p>
                                                                    <ul>
                                                                        <li>
                                                                            <span>
                                                                                Scheduled Start Date
                                                                        </span>
                                                                            {job.scheduledStartDate ? moment.utc(job.scheduledStartDate).local().format("Do MMM YYYY HH:mm") : "Not Scheduled"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Scheduled End Date
                                                                        </span>
                                                                            {job.scheduledEndDate ? moment.utc(job.scheduledEndDate).local().format("Do MMM YYYY HH:mm") : "Not Scheduled"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Case Number
                                                                        </span>
                                                                            {job.caseNumber}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Foreman
                                                                        </span>
                                                                            {job.foreman || "No foreman assigned"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Sub Completion Due Date
                                                                        </span>
                                                                            {job.subCompletionDueDate ? moment.utc(job.subCompletionDueDate).local().format("Do MMM YYYY HH:mm") : "Not Saved"}
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                Practical Completion Actual Date
                                                                        </span>
                                                                            {job.pracCompletionActDate ? moment.utc(job.pracCompletionActDate).local().format("Do MMM YYYY HH:mm") : "Not Saved"}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                    {
                        !this.props.jobCards || Object.keys(this.props.jobCards).length === 0 && !this.props.loading &&
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 90px)' }}>
                            <div>
                                <img src='/media/no-result.png' alt="" />
                                <p style={{ fontSize: '25px', padding: '10px 0' }}>No results found</p>
                            </div>
                        </div>
                    }
                    {
                        this.props.loading &&
                        <>
                            {
                                this.props.jobCards && Object.keys(this.props.jobCards).length > 0 &&
                                <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                            }
                            {
                                !(this.props.jobCards && Object.keys(this.props.jobCards).length > 0) &&
                                <div className="spinner-wrap">
                                    <Spinner style={{ width: '5rem', height: '5rem' }} />
                                </div>
                            }
                        </>
                    }
                    {
                        this.state.jobDetailsView &&
                        <JobDetails jobColors={this.state.jobColors} monthSelected={this.props.monthSelected} toggle={() => {
                            this.jobDetailsViewChange(false)
                        }} toggleList={[]} jobDetails={this.state.jobDetails} statusColors={this.state.statusColors} monthChange={this.props.reload} setRefreshJobList={this.props.setRefreshJobList} setAlert={this.setAlert} />
                    }
                </div >
            </div >
        )
    }
}
export default Cards;

