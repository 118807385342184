import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import ConfirmationModal from '../../../../commonComponents/ConfirmationModal';
import { getCrewList } from '../../../../../api/masterData';
import './index.css';
import ScheduleJob from '../ScheduleJob';
import { rescheduleJob, editCustomJob } from '../../../../../api/jobs';

class DayView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crews: [],
            crewSelected: {
                id: 0,
                name: "",
            },
            draggedJob: {},
            dragFromOutside: false,
            confirmationModal: false,
            confirmationModalMessage: "",
            scheduleModalShow: false,
            disabled: false,
        }
    }

    componentDidMount() {
        getCrewList().then((response) => {
            this.setState({
                crews: response,
            })
        })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.draggedJob != this.props.draggedJob) {
            this.setState({
                draggedJob: this.props.draggedJob,
                dragFromOutside: true,
            })
        }
    }

    onDragStart = (draggedJob) => {
        this.setState({
            draggedJob,
            dragFromOutside: false,
        })
    }

    onEventDrop = (crew) => {
        let message = `Are you sure you want to schedule ${this.state.draggedJob.isCustomJob ? `${this.state.draggedJob.name}` : `job number ${this.state.draggedJob.jobNumber} [ ${this.state.draggedJob.contract} ]`} to ${crew.crew} on ${moment(this.props.date).format("Do MMM YYYY")}?`;
        this.setState({
            crewSelected: crew,
        })
        if (!this.state.dragFromOutside) {
            this.changeConfirmationModal(message, true);
        } else {
            this.changeScheduleModalShow(true)
        }
    }

    changeConfirmationModal = (message, isOpen) => {
        this.setState({
            confirmationModalMessage: message,
            confirmationModal: isOpen,
        })
    }

    changeScheduleModalShow = (show) => {
        this.setState({
            scheduleModalShow: show,
        })
    }

    confirmationModalSubmit = () => {
        this.changeConfirmationModal("", false)
        if (this.state.draggedJob.isCustomJob) {
            let foreman = {
                id: this.state.draggedJob.formanID,
                foreman: this.state.draggedJob.foreman,
            }
            let body = {
                scheduledStartDate: this.state.draggedJob.scheduledStartDate,
                scheduledEndDate: this.state.draggedJob.scheduledEndDate,
                note: this.state.draggedJob.note,
                jobTitle: this.state.draggedJob.name,
                crewID: this.state.crewSelected.id,
                foreman: JSON.stringify(foreman),
            }
            editCustomJob(this.state.draggedJob.id, body).then((response) => {
                this.props.monthChange(this.props.monthSelected);
                this.props.setAlert("Successfully rescheduled " + body.jobTitle, "success")
            }).catch((error) => {
                this.props.setAlert("Error while rescheduling the job", "danger");
            })
        } else {
            let body = {
                semJNumber: this.state.draggedJob.jobNumber,
                caseNumber: this.state.draggedJob.caseNumber,
                note: this.state.draggedJob.note,
                dateReceived: this.state.draggedJob.dateReceived,
                subCompletionDueDate: this.state.draggedJob.subCompletionDueDate,
                scheduledStartDate: this.state.draggedJob.scheduledStartDate,
                scheduledEndDate: this.state.draggedJob.scheduledEndDate,
                crewID: this.state.crewSelected.id,
                contract: this.state.draggedJob.contract,
            }
            rescheduleJob(this.state.draggedJob.id, body).then((response) => {
                this.props.monthChange(this.props.monthSelected);
                this.props.setAlert("Successfully rescheduled the job with job number " + body.semJNumber + " [ " + body.contract + " ]", "success")
            }).catch((error) => {
                this.props.setAlert("Error while rescheduling the job", "danger");
            })
        }
    }

    eventStyleGetter = (statusID) => {
        let backgroundColor = "black";
        this.props.statusColors.map((status) => {
            if (status.statusID == statusID) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }

    render() {
        let backgroundColor = "black";
        const {
            crews,
        } = this.state;
        let jobsInDay = this.props.events.filter((event) => moment(event.scheduledStartDate).isSameOrBefore(moment(this.props.date), 'day') && moment(this.props.date).isSameOrBefore(moment(event.scheduledEndDate), 'day'))
        return (
            <div className="day-container">
                <div className="crew-row">
                    {
                        crews && crews.length > 0 &&
                        crews.map((crew) => {
                            return (
                                <div className="crew-list-column" key={crew.id} onDrop={() => { this.onEventDrop(crew) }} onDragOver={(event) => event.preventDefault()}>
                                    <p className="py-2 m-0 crew-heading" style={{ backgroundColor: crew.colour }}>{crew.crew}</p>
                                    <div className="job-section">
                                        {
                                            jobsInDay.filter((job) => job.crewID === crew.id).map((job, index) => {
                                                return (
                                                    <div key={index} draggable="true" onDragStart={() => { this.onDragStart(job) }} className="day-event m-1 text-left">
                                                        {
                                                            job.isCustomJob ?
                                                                <div onClick={() => { this.props.onSelectEvent(job) }}>
                                                                    <div className="job-title d-flex justify-content-center" style={{ backgroundColor: this.eventStyleGetter(job.statusId) }}>
                                                                        <p>{job.name}</p>
                                                                        {
                                                                            job.customerNotified != "" && job.customerNotified != "No" &&
                                                                            <img src="/media/tick.png" width="20px" height="20px" className="mr-2" style={{ alignSelf: "center" }}></img>

                                                                        }
                                                                    </div>
                                                                    <p><span>Foreman</span>{job.foreman}</p>
                                                                    {
                                                                        job.note &&
                                                                        <p><span>Note</span>
                                                                            {
                                                                                job.note.split('\n').map((line, index) => {
                                                                                    return (
                                                                                        <div key={index}>{line}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </p>
                                                                    }
                                                                </div> :
                                                                <div onClick={() => { this.props.onSelectEvent(job) }}>
                                                                    <div className="job-title d-flex justify-content-center" style={{ backgroundColor: this.eventStyleGetter(job.statusId) }}>
                                                                        <p>{`Job ${job.jobNumber} [ ${job.contract} ]`}</p>
                                                                        {
                                                                            job.customerNotified != "" && job.customerNotified != "No" &&
                                                                            <img src="/media/tick.png" width="20px" height="20px" className="mr-2" style={{ alignSelf: "center" }}></img>
                                                                        }
                                                                    </div>
                                                                    <p>{job.jobDescription}</p>
                                                                    <p><span>Case Number</span> {job.caseNumber}</p>
                                                                    {
                                                                        job.note &&
                                                                        <p><span>Note</span>
                                                                            {
                                                                                job.note.split('\n').map((line, index) => {
                                                                                    return (
                                                                                        <div key={index}>{line}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </p>
                                                                    }
                                                                    <p><span>Sub Comp Due Date</span>{moment(job.subCompletionDueDate).format("Do MMM YYYY")}</p>
                                                                    <p><span>Location</span>{job.streetNumber}, {job.streetName}, {job.suburb}</p>
                                                                </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div >
                <ScheduleJob setAlert={this.props.setAlert} modal={this.state.scheduleModalShow} crewSelected={this.state.crewSelected.id} toggle={() => { this.changeScheduleModalShow(false) }} jobDetails={this.state.draggedJob} startDate={this.props.date} endDate={this.props.date} monthChange={this.props.monthChange} outerModalToggle={null} edit={false} setRefreshJobList={this.props.setRefreshJobList} statusColors={this.props.statusColors} />
                <ConfirmationModal isModal={this.state.confirmationModal} toggleModal={() => { this.changeConfirmationModal(false) }} action={this.confirmationModalSubmit} message={this.state.confirmationModalMessage} headerMessage="Confirm Schedule" isDisabled={this.state.disabled} />
            </div >
        )
    }
}

DayView.title = (params) => {
    return moment(params).format("Do MMMM")
}

DayView.navigate = (date, action) => {
    switch (action) {
        case "PREV":
            return moment(date).add(-1, 'day').toDate();

        case "NEXT":
            return moment(date).add(1, 'day').toDate();

        default:
            return date;
    }
}

export default DayView;