import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Alert, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import moment from 'moment-timezone';
import { scheduleJob, rescheduleJob, listJobs, getNotes } from '../../../../../api/jobs';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets-moment';
import { getCrewList } from '../../../../../api/masterData';
import './index.css';

moment.locale('en')
momentLocalizer()


class ScheduleJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            isConfirmed: false,
            note: "",
            alertShow: false,
            alertMessage: "",
            isSubmitButtonDisabled: false,
            crews: [],
            crewSelected: 0,
            schedulableJobs: [],
            unschedulableJobs: [],
            disabled: false,
            selectedJobNumbers: [],
        }
        this.sameCaseNumberJobs = {};
        this.tz = moment.tz.guess();
    }

    componentDidMount = () => {
        getCrewList().then((response) => {
            this.setState({
                crews: response,
            })
        }).catch((error) => {
            console.log("Error while getting crew list ", error)
        })
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.getNote()
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                isConfirmed: this.props.jobDetails.isThirdparty === "yes" ? true : false,
                note: this.props.jobDetails.note,
                crewSelected: this.props.edit ? this.props.jobDetails.crewID : this.props.crewSelected,
                disabled: true,
            }, () => {
                let body = {
                    searchValue: this.props.jobDetails.caseNumber,
                    complete: true,
                }
                listJobs(body).then((response) => {
                    let sameCaseNumberJobs = {}
                    if (response.data != null && response.data.length > 0) {
                        response.data.forEach(job => {
                            if (!sameCaseNumberJobs[job.jobNumber]) {
                                sameCaseNumberJobs[job.jobNumber] = job;
                                sameCaseNumberJobs[job.jobNumber].workOrders = [{
                                    jobTypeCode: job.jobTypeCode,
                                    workOrderNumber: job.workOrderNumber,
                                }];
                                delete sameCaseNumberJobs.jobTypeCode;
                                delete sameCaseNumberJobs.workOrderNumber;
                            } else {
                                sameCaseNumberJobs[job.jobNumber].workOrders.push({
                                    jobTypeCode: job.jobTypeCode,
                                    workOrderNumber: job.workOrderNumber,
                                })
                            }
                        });
                    }
                    this.sameCaseNumberJobs = sameCaseNumberJobs;
                    this.setState({
                        disabled: false,
                    })
                    this.getSchedulableJobs();
                })
            })
        }

    }

    getNote = () => {
        let params = {
            semJNumber: this.props.jobDetails.jobNumber,
            contract: this.props.jobDetails.contract,
        }
        getNotes(params).then((response) => {
            this.setState({
                note: response.note,
            })
        }).catch((error) => {
            this.setState({
                note: "",
            })
        })
    }

    getSchedulableJobs = () => {
        let schedulableJobs = [];
        let unschedulableJobs = [];
        for (const jobNumber in this.sameCaseNumberJobs) {
            if (moment(this.sameCaseNumberJobs[jobNumber].dateReceived).isSameOrBefore(moment(this.state.startDate), 'day') && moment(this.state.endDate).isSameOrBefore(moment(this.sameCaseNumberJobs[jobNumber].subCompletionDueDate), 'day')) {
                if (jobNumber != this.props.jobDetails.jobNumber) {
                    schedulableJobs.push(this.sameCaseNumberJobs[jobNumber]);
                }
            } else {
                unschedulableJobs.push(this.sameCaseNumberJobs[jobNumber])
            }
        }
        this.setState({
            schedulableJobs,
            unschedulableJobs,
        })
    }
    changeStartDate = (date) => {
        if (!moment(date).isValid()) {
            this.setAlert("Invalid format entered for start date", "danger");
            return
        }
        this.setState({
            startDate: moment(date).utc().format(),
        }, () => {
            if (moment(this.state.startDate).isAfter(moment(this.state.endDate), "day")) {
                this.setState({
                    endDate: this.state.startDate,
                }, this.getSchedulableJobs)
            } else {
                this.getSchedulableJobs();
            }
        })
    }
    changeEndDate = (date) => {
        if (!moment(date).isValid()) {
            this.setAlert("Invalid format entered for end date", "danger");
            return
        }
        this.setState({
            endDate: moment(date).utc().format(),
        }, this.getSchedulableJobs)
    }
    changeCrew = (id) => {
        this.setState({
            crewSelected: parseInt(id),
        })
    }
    toggleConfirm = () => {
        this.setState((prevState) => ({
            isConfirmed: !prevState.isConfirmed,
        }))
    }
    changeNote = (note) => {
        this.setState({
            note,
        })
    }

    toggleIsSubmitButtonDisabled = () => {
        this.setState((prevState) => ({
            isSubmitButtonDisabled: !prevState.isSubmitButtonDisabled,
        }))
    }

    submit = () => {
        this.toggleIsSubmitButtonDisabled()
        let isThirdparty
        if (this.state.isConfirmed == true)
            isThirdparty = 'yes'
        else
            isThirdparty = 'no'
        let selectedJobNumbers = this.state.selectedJobNumbers;
        if (selectedJobNumbers.includes(this.props.jobDetails.jobNumber)) {
            const index = selectedJobNumbers.indexOf(this.props.jobDetails.jobNumber);
            if (index > -1) {
                selectedJobNumbers.splice(index, 1);
            }
        }
        let body = {
            isThirdparty: isThirdparty,
            note: this.state.note,
            semJNumber: this.props.jobDetails.jobNumber,
            scheduledStartDate: this.state.startDate,
            scheduledEndDate: this.state.endDate,
            subCompletionDueDate: this.props.jobDetails.subCompletionDueDate,
            dateReceived: this.props.jobDetails.dateReceived,
            crewID: this.state.crewSelected,
            caseNumber: this.props.jobDetails.caseNumber,
            excludedJobNumbers: selectedJobNumbers,
            contract: this.props.jobDetails.contract,
        }
        if (!body.crewID) {
            this.setAlert("Enter the crew");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (moment(body.dateReceived).isAfter(moment(body.scheduledStartDate), 'day')) {
            this.setAlert("Start date should be greater than date received");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (moment(body.scheduledEndDate).isAfter(moment(body.subCompletionDueDate), 'day')) {
            this.setAlert("End date should be lesser than completion date");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (moment(body.scheduledStartDate).isAfter(moment(body.scheduledEndDate))) {
            this.setAlert("End date should be greater than start date");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (!this.props.edit) {
            scheduleJob(body).then((response) => {
                this.props.monthChange(this.state.endDate)
                this.props.toggle();
                if (this.props.outerModalToggle != null) {
                    this.props.outerModalToggle();
                }
                this.props.setRefreshJobList(true);
                this.closeAlert();
                this.props.setAlert(`Job Number ${this.props.jobDetails.jobNumber} [ ${this.props.jobDetails.contract} ] successfully scheduled`, "success")
                this.toggleIsSubmitButtonDisabled();
                if (this.props.closeAll != undefined) {
                    this.props.closeAll();
                }
            }).catch((error) => {
                console.log("Error while scheduling job", error)
                this.setAlert("Could not schedule job. Please try again");
                this.toggleIsSubmitButtonDisabled()
            })
        }
        else {
            rescheduleJob(this.props.jobDetails.id, body).then((response) => {
                // this.props.monthChange(this.state.endDate)
                this.props.monthChange(this.state.endDate, () => {
                    if (this.props.dayDetailsShow && this.props.dateSelected) {
                        this.props.dateClicked({ start: this.props.dateSelected })
                    }
                });
                this.props.toggle();
                if (this.props.outerModalToggle != null) {
                    this.props.outerModalToggle();
                }
                this.props.setRefreshJobList(true);
                this.closeAlert();
                this.props.setAlert(`Job Number ${this.props.jobDetails.jobNumber} successfully rescheduled`, "success")
                this.toggleIsSubmitButtonDisabled();
                if (this.props.closeAll != undefined) {
                    this.props.closeAll();
                }
            }).catch((error) => {
                console.log("Error while scheduling job", error)
                this.setAlert("Could not schedule job. Please try again");
                this.toggleIsSubmitButtonDisabled()
            })
        }
    }

    setAlert = (alertMessage) => {
        this.setState({
            alertShow: true,
            alertMessage,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertShow: false,
                    alertMessage: "",
                })
            }, 1500)
        })
    }
    closeAlert = () => {
        this.setState({
            alertShow: false,
            alertMessage: "",
        })
    }

    eventStyleGetter = (statusID) => {
        let backgroundColor = "#f8f9fa";
        this.props.statusColors.map((status) => {
            if (status.statusID == statusID) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }

    checkBoxClicked = (jobNumber) => {
        let selectedJobNumbers = this.state.selectedJobNumbers;
        if (selectedJobNumbers.includes(jobNumber)) {
            const index = selectedJobNumbers.indexOf(jobNumber);
            if (index > -1) {
                selectedJobNumbers.splice(index, 1);
            }

        } else {
            selectedJobNumbers.push(jobNumber)
        }
        this.setState({
            selectedJobNumbers,
        })
    }
    render() {
        const isConfirmed = this.state.isConfirmed
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    <h1 className="modal-header-text"><span>Schedule Job /</span> Job Number: {this.props.jobDetails.jobNumber} [ {this.props.jobDetails.contract} ]</h1>
                </ModalHeader>
                <ModalBody>
                    <Alert isOpen={this.state.alertShow} toggle={this.closeAlert} color="danger">{this.state.alertMessage}</Alert>
                    <div>
                        <p>Start Date</p>
                        <DateTimePicker
                            value={new Date(this.state.startDate)}
                            onChange={this.changeStartDate}
                            min={new Date(new Date(this.props.jobDetails.dateReceived).setHours(0, 0, 0, 0))}
                            max={new Date(new Date(this.props.jobDetails.subCompletionDueDate).setHours(23, 59, 59, 999))}
                        />
                    </div>
                    <div>
                        <p>End Date</p>
                        <DateTimePicker
                            value={new Date(this.state.endDate)}
                            onChange={this.changeEndDate}
                            min={new Date(new Date(this.props.jobDetails.dateReceived).setHours(0, 0, 0, 0))}
                            max={new Date(new Date(this.props.jobDetails.subCompletionDueDate).setHours(23, 59, 59, 999))}
                        />
                    </div>
                    <div>
                        <p>Crew</p>
                        <Input type="select" name="crew" id="crew" rows="6" value={this.state.crewSelected} onChange={(e) => this.changeCrew(e.target.value)}>
                            <option>Choose a crew</option>
                            {
                                this.state.crews && this.state.crews.length > 0 &&
                                this.state.crews.map((crew) => {
                                    return (
                                        <option value={crew.id} key={crew.id}>{crew.crew}</option>
                                    )
                                })
                            }
                        </Input>

                    </div>
                    {
                        ((this.state.schedulableJobs && this.state.schedulableJobs.length > 0) || (this.state.unschedulableJobs && this.state.unschedulableJobs.length > 0)) && !this.props.edit &&
                        <div className="mt-3">
                            <p>Jobs with same case number</p>
                            <div style={{ fontSize: "12px" }}>Check these jobs to add them to schedule</div>
                            <div className="casenumber-box my-2" style={{ maxHeight: "300px", overflow: "auto" }}>
                                {
                                    this.state.schedulableJobs && this.state.schedulableJobs.length > 0 &&
                                    this.state.schedulableJobs.map((job) => {
                                        return (
                                            <div className="job-card px-1 my-2 mx-1 bg-light no-click" key={job.jobNumber} style={{ borderLeftColor: this.eventStyleGetter(job.statusID) }}>
                                                <div className="heading">{`Job Number  ${job.jobNumber} [ ${job.contract} ]`}</div>
                                                <div className="d-flex justify-content-between">
                                                    <div>{`Job Description - ${job.jobDescription}`}</div>
                                                    <Input type="checkbox" style={{ position: "relative" }} checked={!(this.state.selectedJobNumbers.includes(job.jobNumber))} onChange={() => { this.checkBoxClicked(job.jobNumber) }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.unschedulableJobs && this.state.unschedulableJobs.length > 0 &&
                                    this.state.unschedulableJobs.map((job) => {
                                        return (
                                            <div className="job-card px-1 my-2 mx-1 bg-light text-black-50 no-click" key={job.jobNumber} style={{ borderLeftColor: this.eventStyleGetter(job.statusID) }}>
                                                <div className="heading">{`Job Number  ${job.jobNumber} [ ${job.contract}]`}<span className="float-right info text-danger">*</span></div>
                                                <div>{`Job Description - ${job.jobDescription}`}<Input type="checkbox" style={{ right: "23px" }} checked={!(this.state.selectedJobNumbers.includes(job.jobNumber))} onChange={() => { this.checkBoxClicked(job.jobNumber) }} /></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.unschedulableJobs && this.state.unschedulableJobs.length > 0 &&
                                <div className="float-right info text-danger mb-3 mt-2" style={{ fontSize: "12px" }}>* - The selected dates is not in between the date received and sub completion date of this job, but this will be added to these dates if you keep these checked and submit</div>
                            }
                        </div>
                    }
                    {
                        !((this.state.schedulableJobs && this.state.schedulableJobs.length > 0) || (this.state.unschedulableJobs && this.state.unschedulableJobs.length > 0)) &&
                        <div className="my-2">There are no jobs with the same case number which are yet to be scheduled</div>
                    }
                    <div className="custom-control custom-checkbox my-3">
                        <Input className="custom-control-input" name="rdParty2" id="rdParty2" type="checkbox" checked={this.state.isConfirmed} onChange={this.toggleConfirm} />
                        <Label className="custom-control-label" for="rdParty2">3rd Party Confirmed</Label>
                    </div>
                    <div>
                        <p>Notes</p>
                        <Input type="textarea" name="text" id="exampleText" rows="6" value={this.state.note} onChange={(e) => { this.changeNote(e.target.value) }} />
                    </div>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <Button className="mr-2" color="primary" disabled={this.state.isSubmitButtonDisabled} onClick={this.submit}>Submit</Button>
                            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }
}

export default ScheduleJob;