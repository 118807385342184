import React, { Fragment } from 'react';
import Sidepanel from './Sidepanel';
import Cal from './Calendar';
import { listJobs, listScheduledJobs, listAllJobs, searchCustomJob } from '../../../api/jobs';
import moment from 'moment-timezone';
import Cards from './Cards';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            events: [],
            caseNumber: "",
            jobNumber: "",
            jobType: "",
            zone: "",
            councilName: "",
            suburb: "",
            foreman: "",
            crewID: 0,
            jobTitle: "",
            page: 1,
            isThirdparty: "no",
            monthSelected: moment().format(),
            loading: false,
            cardView: false,
            customSearch: false,
            jobDetails: {
                job: {},
                placeRight: false,
            },
            jobDetailsView: false,
            refreshJobList: false,
            draggedJob: {},
        }
        this.lastPage = false;
    }
    componentDidMount = () => {
        this.monthChange(this.state.monthSelected)
    }

    searchJobs = () => {
        let params = {}
        if (this.state.customSearch) {
            params = {
                foreman: this.state.foreman,
                crewID: this.state.crewID,
                title: this.state.jobTitle,
                page: 1,
            }
        } else {
            params = {
                caseNumber: this.state.caseNumber,
                jobNumber: this.state.jobNumber,
                jobType: this.state.jobType,
                zone: this.state.zone,
                councilName: this.state.councilName,
                suburb: this.state.suburb,
                foreman: this.state.foreman,
                page: 1,
                // isThirdparty: this.state.isThirdparty
            };
        }
        this.setState({
            page: 1,
            jobs: [],
            loading: true,
        }, () => {
            let getList;
            if (this.state.customSearch) {
                getList = searchCustomJob
            } else {
                getList = listAllJobs
            }
            getList(params).then((response) => {
                this.setState({
                    jobs: response.data,
                    loading: false,
                })
            }).catch((error) => {
                console.log("Error while listing jobs ", error)
                this.setState({
                    loading: false,
                })
            })
        })
    }

    generateJobCards = (jobs) => {
        let jobCards = {};
        let month = "";
        jobs.forEach((job, index) => {
            let grouping = this.state.customSearch ? "scheduledEndDate" : "subCompletionDueDate";
            month = moment.utc(job[grouping]).local().format('MMMM YYYY');
            if (jobCards[month]) {
                jobCards[month].push(job)
            } else {
                jobCards[month] = [job];
            }
        });
        return jobCards;
    }
    filterClicked = () => {
        this.setState({
            cardView: true,
        }, () => {
            this.searchJobs();
        })
    }

    pageChange = () => {
        if (!this.lastPage) {
            this.setState((prevState) => ({
                page: prevState.page + 1,
                loading: true,
            }), () => {

                let params = {}
                if (this.state.customSearch) {
                    params = {
                        foreman: this.state.foreman,
                        crewID: this.state.crewID,
                        title: this.state.jobTitle,
                        page: this.state.page,
                    }
                } else {
                    params = {
                        caseNumber: this.state.caseNumber,
                        jobType: this.state.jobType,
                        zone: this.state.zone,
                        councilName: this.state.councilName,
                        suburb: this.state.suburb,
                        foreman: this.state.foreman,
                        page: this.state.page,
                        jobNumber: this.state.jobNumber,
                    };
                }
                let getList;
                if (this.state.customSearch) {
                    getList = searchCustomJob
                } else {
                    getList = listAllJobs
                }
                getList(params).then((response) => {
                    if (response.data.length == 0) {
                        this.lastPage = true;
                    } else {
                        let jobs = this.state.jobs.slice(0, this.state.jobs.length)
                        this.setState({
                            jobs: jobs.concat(response.data),
                        })
                    }
                    this.setState({
                        loading: false,
                    })
                }).catch((error) => {
                    console.log("Error while listing jobs ", error)
                    this.setState({
                        loading: false,
                    })
                })
            })
        }
    }

    backClicked = () => {
        this.setState({
            cardView: false,
            customSearch: false,
        }, () => {
            this.monthChange(this.state.monthSelected)
        })
    }

    customSearchClicked = () => {
        this.setState({
            customSearch: true,
            cardView: true,
        })
    }

    //valueChange handles any change in filter values except change in month. Change in month affects calendar view only and the rest are for list view
    valueChange = (key, value) => {
        this.setState({
            [key]: value,
        })
    }

    //monthChange handles change in month selection in calendar view and loads new set of jobs
    monthChange = (month, callback) => {
        this.setState({
            monthSelected: month,
            loading: true,
        })
        let params = {
            month: moment(month).local().format(),
        }
        listScheduledJobs(params).then((response) => {
            let events = [];
            response.data.forEach(job => {
                let item = Object.assign({}, job);
                item.isConfirmed = job.isThirdParty;
                delete job.isThirdParty;
                item.statusId = job.statusID;
                delete item.statusID;
                item.title = job.jobNumber ? ` Job ${job.jobNumber} [ ${job.contract} ] ${job.foreman} \n ${job.jobDescription}` : `${job.title} ${job.foreman}`;
                item.isCustomJob = job.isCustomJob;
                item.start = moment.utc(job.scheduledStartDate).toDate();
                item.end = moment.utc(job.scheduledEndDate).toDate();
                item.allDay = true;
                item.crewID = job.crewID;
                item.crew = job.crew;
                item.name = job.title;
                item.contract = job.contract;
                events.push(item)
            });
            this.setState({
                events,
                loading: false,
                jobs: response.data,
            }, () => {
                if (callback) {
                    callback();
                }
            })
        }).catch((error) => {
            console.log("Error while listing jobs ", error)
            this.setState({
                loading: false,
            })
        })
    }

    jobCardClicked = (job, placeRight) => {
        this.setState({
            jobDetails: {
                job,
                placeRight,
            },
            jobDetailsView: true,
        })
    }

    jobDragged = (job) => {
        this.setState({
            draggedJob: Object.assign({}, job)
        })
    }

    jobDetailsHide = () => {
        this.setState({
            jobDetailsView: false,
        })
    }

    setRefreshJobList = (value) => {
        this.setState({
            refreshJobList: value,
        })
    }

    // reorderEvents = (id) => {
    //     console.log("id ", id)
    //     let events = this.state.events.slice(0, this.state.events.length);
    //     console.log("events before", events)
    //     this.state.events.forEach((event, index) => {
    //         if (event.id === id) {
    //             events.splice(index, 1)
    //             events.unshift(event)
    //         }
    //     })
    //     console.log("events after", events)
    //     this.setState({
    //         events,
    //     })
    // }
    render() {
        const {
            caseNumber,
            jobType,
            zone,
            councilName,
            suburb,
            foreman,
            monthSelected,
            jobs,
        } = this.state;
        return (
            <Fragment>
                <Sidepanel caseNumber={caseNumber} jobType={jobType} zone={zone} councilName={councilName} suburb={suburb} refreshJobList={this.state.refreshJobList} setRefreshJobList={this.setRefreshJobList}
                    foreman={foreman} valueChange={this.valueChange} monthChange={this.monthChange} monthSelected={monthSelected} filterClicked={this.filterClicked} cardView={this.state.cardView} backClicked={this.backClicked} jobCardClicked={this.jobCardClicked} jobDragged={this.jobDragged} customSearch={this.state.customSearch} customSearchClicked={this.customSearchClicked} loading={this.state.loading} jobTitle={this.state.jobTitle} foreman={this.state.foreman} crewID={this.state.crewID} caseNumber={this.state.caseNumber} jobNumber={this.state.jobNumber} zone={this.state.zone} councilName={this.state.councilName} suburb={this.state.suburb}></Sidepanel>
                {
                    this.state.cardView &&
                    <Cards setRefreshJobList={this.setRefreshJobList} jobCards={this.generateJobCards(jobs)} pageChange={this.pageChange} monthSelected={monthSelected} reload={this.searchJobs} loading={this.state.loading} />
                }
                {
                    !this.state.cardView &&
                    <Cal setRefreshJobList={this.setRefreshJobList} jobCardClicked={this.jobCardClicked} jobs={jobs} selectedJob={this.state.jobDetails} jobDetailsView={this.state.jobDetailsView} jobDetailsHide={this.jobDetailsHide} events={this.state.events} monthSelected={monthSelected} monthChange={this.monthChange} loading={this.state.loading} draggedJob={this.state.draggedJob}></Cal>
                }
            </Fragment >
        )
    }
}

export default Home;