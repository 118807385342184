import React, { Fragment } from "react";
import moment from "moment-timezone";
import ScheduleJob from "../ScheduleJob";
import { deleteJob, listAllJobs, getNotes, addNotes, editCustomJob, searchCustomJob, deleteCustomJob } from "../../../../../api/jobs";
import ConfirmationModal from "../../../../commonComponents/ConfirmationModal";
import { listJobColors } from "../../../../../api/masterData";
import { Spinner, Button, Input } from "reactstrap";
import './index.css'
import DeleteJob from "./DeleteJob";
import AddNotes from "./AddNotes";
import ScheduleCustomJob from "../../customJob/ScheduleCustomJob";
import { updateCloseJobs } from "../../Functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class JobDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobAssignModal: false,
            removeConfirmationModal: false,
            isRemovalButton: false,
            edit: false,
            sameCaseNumberJobs: [],
            disabled: false,
            innerJobDetailsShow: false,
            innerJobDetails: {
                job: {},
                placeRight: false,
            },
            addNotesModalShow: false,
            note: "",
            noteSubmitDisabled: false,
            editCustomJobModalShow: false,
            customJobDeleteModal: false,
            dbydDate: "",
            customerNotified: "",
        }
    }

    componentDidMount = () => {
        if (this.props.jobDetails.job.note) {
            this.setState({
                note: this.props.jobDetails.job.note,
                dbydDate: this.props.jobDetails.job.dbydDate,
                customerNotified: this.props.jobDetails.job.customerNotified,
            })
        } else {
            this.getNote();
        }
        this.getCaseNumberList();
        updateCloseJobs(this.closeAll)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.jobDetails.job.jobNumber != prevProps.jobDetails.job.jobNumber) {
            this.getCaseNumberList();
            if (this.props.jobDetails.job.note) {
                this.setState({
                    note: this.props.jobDetails.job.note,
                })
            } else {
                this.getNote();
            }
        }
    }

    toggleCustomJobDeleteModal = (value) => {
        this.setState({
            customJobDeleteModal: value,
        })
    }

    submitDeleteCustomJob = () => {
        this.setState({
            disabled: true,
        }, () => {
            deleteCustomJob(this.props.jobDetails.job.id).then((response) => {
                this.props.toggle()
                this.props.monthChange(this.props.monthSelected, () => {
                    if (this.props.dayDetailsShow && this.props.dateSelected) {
                        this.props.dateClicked({ start: this.props.dateSelected })
                    }
                });
                this.props.setAlert("Successfully deleted  " + this.props.jobDetails.job.title)
                this.toggleCustomJobDeleteModal(false)
            }).catch((error) => {
                this.props.setAlert("Failed to delete " + this.props.jobDetails.job.title, "danger")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    getNote = () => {
        if (this.props.jobDetails.job.isCustomJob) {
            let params = {
                id: this.props.jobDetails.job.id,
            }
            searchCustomJob(params).then((response) => {
                this.setState({
                    note: response.data[0].note,
                    customerNotified: response.data[0].customerNotified,
                    dbydDate: response.data[0].dbydDate,
                })
            }).catch((error) => {
                this.setState({
                    note: "",
                })
            })
        } else {
            let params = {
                semJNumber: this.props.jobDetails.job.jobNumber,
                contract: this.props.jobDetails.job.contract,
            }
            getNotes(params).then((response) => {
                this.setState({
                    note: response.note,
                    customerNotified: response.customerNotified,
                    dbydDate: response.dbydDate,
                })
            }).catch((error) => {
                this.setState({
                    note: "",
                })
            })
        }
    }
    changeAddNotesModalShow = (value) => {
        this.setState({
            addNotesModalShow: value,
        })
    }

    innerJobDetailsShowChange = (value) => {
        this.setState({
            innerJobDetailsShow: value,
        })
    }

    jobCardClicked = (job) => {
        let innerJobDetails = {
            job,
            placeRight: this.props.jobDetails.placeRight,
        }
        this.setState({
            innerJobDetails,
        }, () => {
            this.innerJobDetailsShowChange(true);
        })
    }

    getCaseNumberList = () => {
        if (this.props.jobDetails.job.caseNumber) {
            let params = {
                caseNumber: this.props.jobDetails.job.caseNumber,
                complete: true,
            }
            this.setState({
                disabled: true,
                sameCaseNumberJobs: [],
            }, () => {
                listAllJobs(params).then((response) => {
                    this.setState({
                        sameCaseNumberJobs: response.data,
                        disabled: false,
                    })
                }).catch((error) => {
                    console.log("Error while getting list of jobs with same case number")
                    this.setState({
                        disabled: false,
                    })
                })
            })
        }
    }

    toggleEditCustomJob = (value) => {
        this.setState({
            editCustomJobModalShow: value,
        })
    }
    toggleJobAssignModal = () => {
        this.setState((prevState) => ({
            jobAssignModal: !prevState.jobAssignModal,
        }))
    }

    toggleEdit = () => {
        this.setState(() => ({
            edit: true
        }))
    }

    toggleSubmit = () => {
        this.setState(() => ({
            edit: false
        }))
    }

    toggleIsRemovalButton = () => {
        this.setState((prevState) => ({
            isRemovalButton: !prevState.isRemovalButton,
        }))
    }

    jobDelete = (all) => {
        this.toggleIsRemovalButton()
        let body = {
            id: this.props.jobDetails.job.id,
        }
        if (all) {
            body.caseNumber = this.props.jobDetails.job.caseNumber;
        }
        deleteJob(body).then((response) => {
            this.props.toggle()
            this.props.monthChange(this.props.monthSelected, () => {
                if (this.props.dayDetailsShow && this.props.dateSelected) {
                    this.props.dateClicked({ start: this.props.dateSelected })
                }
            });
            this.props.setRefreshJobList(true)
            this.props.setAlert("Successfully deleted the job with job number " + this.props.jobDetails.job.jobNumber + " [ " + this.props.jobDetails.job.contract + " ]")
            this.toggleIsRemovalButton();
            this.closeAll();
        }).catch((error) => {
            this.toggleRemoveConfirmationModal()
            this.props.setAlert("Failed to delete the job with job number " + this.props.jobDetails.job.jobNumber + " [ " + this.props.jobDetails.job.contract + " ]", "danger")
            this.toggleIsRemovalButton()
        })
    }

    toggleRemoveConfirmationModal = () => {
        this.setState((prevState) => ({
            removeConfirmationModal: !prevState.removeConfirmationModal,
        }))
    }

    eventStyleGetter = (statusID) => {
        let backgroundColor = "#f8f9fa";
        this.props.statusColors.map((status) => {
            if (status.statusID == statusID) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }

    closeAll = () => {
        this.props.toggleList.forEach(toggle => {
            toggle();
        });
        this.props.toggle();
    }

    noteEditSubmit = (note) => {
        let notRescheduled = false
        if (!note) {
            note = this.state.note
            notRescheduled = true
        }
        let job = this.props.jobDetails.job;
        this.setState({
            noteSubmitDisabled: true,
        }, () => {
            if (job.isCustomJob) {
                let body = {
                    scheduledStartDate: job.scheduledStartDate,
                    scheduledEndDate: job.scheduledEndDate,
                    note: note,
                    dbydDate: this.state.dbydDate,
                    customerNotified: this.state.customerNotified,
                    jobTitle: job.title,
                    crewID: job.crewID,
                    notRescheduled,
                    foreman: JSON.stringify({
                        id: job.formanID,
                        foreman: job.foreman,
                    }),
                }
                editCustomJob(job.id, body).then((response) => {
                    this.setState({
                        noteSubmitDisabled: false,
                    })
                    this.changeAddNotesModalShow(false);
                    this.getNote();
                    this.props.monthChange(this.props.monthSelected)
                }).catch((error) => {
                    console.log("Couldn't update note");
                    this.setState({
                        noteSubmitDisabled: false,
                    })
                })
            } else {
                let body = {
                    semJNumber: job.jobNumber,
                    caseNumber: job.caseNumber,
                    note,
                    contract: job.contract,
                    dbydDate: this.state.dbydDate,
                    customerNotified: this.state.customerNotified,
                }
                addNotes(body).then((response) => {
                    this.setState({
                        noteSubmitDisabled: false,
                    })
                    this.changeAddNotesModalShow(false);
                    this.getNote();
                    this.props.monthChange(this.props.monthSelected)
                    this.props.setRefreshJobList(true)
                }).catch((error) => {
                    console.log("Couldn't update note");
                    this.setState({
                        noteSubmitDisabled: false,
                    })
                })
            }
        })
    }

    handleDBYDChange = (date) => {
        this.setState({
            dbydDate: date,
        }, this.noteEditSubmit)
    }

    changeCustomerNotified = (e) => {
        this.setState({
            customerNotified: e.target.value,
        }, this.noteEditSubmit)
    }

    render() {
        const {
            job,
            placeRight,
        } = this.props.jobDetails;
        const {
            jobAssignModal,
            sameCaseNumberJobs,
            disabled,
            note,
        } = this.state;
        let role = JSON.parse(localStorage.getItem("schedulingApp")).role;
        let color = this.props.jobColors.length > 0 && this.props.jobColors[this.props.jobColors.length - 1].colour;
        this.props.jobColors.map((jobColor) => {
            if (job && job.jobDescription.toLowerCase().includes(jobColor.job)) {
                color = jobColor.colour
            }
        })
        let toggleList = this.props.toggleList.slice(0, this.props.toggleList.length);
        toggleList.push(this.props.toggle);
        const datePickerProp = {
            onChange: this.handleDBYDChange,
            dateFormat: "do MMMM yyyy",
            placeholderText: "Choose a Date",
            isClearable: true,
        }
        datePickerProp.selected = this.state.dbydDate ? new Date(this.state.dbydDate) : null
        return (
            <div className="side-pop-bg ">
                {
                    job && job.jobNumber &&
                    <div className={placeRight ? "side-pop float-right" : "side-pop"}>
                        <div className="side-pop-header position-relative" style={{ backgroundColor: color }}>
                            <span className="close-pop"><img onClick={this.props.toggle} src="/media/close-pop.png" alt="" /></span>
                            <div className="row">
                                <div className="col-8 text-left">
                                    <h1>SEM Job Number : {job.jobNumber} [ {job.contract} ]
                                        {
                                            job.jobActive != "true" &&
                                            <span className="inactive">inactive</span>
                                        }
                                    </h1>
                                    <p>{job.jobDescription}</p>
                                </div>
                                {
                                    !job.scheduledStartDate && (role == "supervisor" || role == "administrator") &&
                                    <div className="col-4 d-flex align-items-center">
                                        <a onClick={(e) => { e.preventDefault(); this.toggleJobAssignModal(); this.toggleSubmit(); }} href="" style={{ backgroundColor: 'burlywood' }}>Add To Calendar</a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="side-pop-body">
                            {
                                job.scheduledStartDate &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="preCon-card d-flex">
                                            <p>The job has been scheduled from <strong>{moment.utc(job.scheduledStartDate).local().format("Do MMMM YYYY hh:mm a")}</strong> till <strong>{moment.utc(job.scheduledEndDate).local().format("Do MMMM YYYY hh:mm a")}</strong></p>

                                            {
                                                job.scheduledStartDate && (role == "supervisor" || role == "administrator") &&
                                                <div className="col-4 d-flex align-items-center justify-content-end">
                                                    <a className="edit mr-2" onClick={(e) => { e.preventDefault(); this.toggleEdit(); this.toggleJobAssignModal() }} href="">Edit</a>
                                                    <a onClick={(e) => { e.preventDefault(); this.toggleRemoveConfirmationModal() }} href="">Remove</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <h3>Address  :</h3>
                            <p>{job.streetNumber}, {job.streetName}, {job.suburb}</p>
                            <h3>Work Orders :</h3>
                            <table className="table table-bordered">
                                <tbody>
                                    {
                                        job.workOrders && job.workOrders.length > 0 &&
                                        job.workOrders.map((workOrder) => {
                                            return (
                                                <tr key={workOrder.workOrderNumber}>
                                                    <td>Number : {workOrder.workOrderNumber}</td>
                                                    <td>Type : {workOrder.jobTypeCode}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <h3>Case Number  :<span>{job.caseNumber}</span></h3>
                            {
                                sameCaseNumberJobs && sameCaseNumberJobs.length > 1 &&
                                <div>
                                    <h3>Jobs with same case number</h3>
                                    <div className="casenumber-box my-2" style={sameCaseNumberJobs.length > 2 ? { overflow: "auto", height: "200px", } : {}}>
                                        {
                                            sameCaseNumberJobs.filter((caseNumberJob) => caseNumberJob.jobNumber != job.jobNumber).map((job) => {
                                                return (
                                                    <div className="job-card p-1 m-1 bg-light" key={job.jobNumber} onClick={() => { this.jobCardClicked(job) }} style={{ borderLeftColor: this.eventStyleGetter(job.statusID) }}>
                                                        <p className="heading">{`Job Number - ${job.jobNumber} [ ${job.contract} ]`}</p>
                                                        <p>{`Job Description - ${job.jobDescription}`}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                disabled &&
                                <>
                                    <h3>Jobs with same case number</h3>
                                    <div className="text-center h-100 p-4">
                                        <Spinner color="primary" />
                                    </div>
                                </>
                            }
                            {
                                sameCaseNumberJobs.length <= 1 && !disabled &&
                                <div className="my-2">There are no other jobs with the same case number</div>
                            }
                            <h3>Foreman  :<span>{job.foreman || "No Foreman Assigned"}</span></h3>
                            <h3>Crew Assigned  :
                                    {
                                    job.crew &&
                                    <div className="d-flex align-items-center crew-box">
                                        <span style={{ backgroundColor: job.crewColour }}>
                                            {job.crew}
                                        </span>
                                    </div>
                                }
                                {
                                    !job.crew &&
                                    <span>No Crew Assigned</span>
                                }
                            </h3>
                            <h3>Received Date :  <span>{moment.utc(job.dateReceived).local().format("Do MMMM YYYY")}</span></h3>
                            <h3>Sub completion due date : <span>{moment.utc(job.subCompletionDueDate).local().format("Do MMMM YYYY")}</span></h3>
                            <h3>DBYD Date  :
                            {
                                    (role == "supervisor" || role == "administrator") ?
                                        <DatePicker
                                            {...datePickerProp}
                                        /> :
                                        <span>{this.state.dbydDate ? moment.utc(this.state.dbydDate).local().format("Do MMMM YYYY") : "Not Saved"}</span>
                                }
                            </h3>
                            <h3>Practical completion Actual date : <span>{job.pracCompletionActualDate ? moment.utc(job.pracCompletionActualDate).local().format("Do MMMM YYYY") : "Not Saved"}</span></h3>
                            <h3>Zone  : <span>{job.zone}</span></h3>
                            <h3>Council Code  : <span>{job.councilName}</span></h3>
                            {
                                (job.isThirdparty == "yes" || job.isThirdparty == "no") &&
                                <h3>3rd Party Confirmation : <span>{job.isThirdparty}</span></h3>
                            }
                            <h3>Customer Notified  :<span>
                                {
                                    (role == "supervisor" || role == "administrator") ?
                                        <Input type="select" name="customerNotified" id="customerNotified" value={this.state.customerNotified} onChange={this.changeCustomerNotified}>
                                            <option>No</option>
                                            <option>Spoken to customer</option>
                                            <option>Left a message</option>
                                        </Input> :
                                        <span>{this.state.customerNotified || "No"}</span>
                                }
                            </span></h3>
                            <h3 className="d-block">Notes  :
                                    {
                                    <>
                                        {
                                            note &&
                                            <span>
                                                {
                                                    (role == "supervisor" || role == "administrator") &&
                                                    <img src="/media/edit.png" width="13" className="ml-2" alt="" style={{ cursor: "pointer" }} onClick={() => { this.changeAddNotesModalShow(true) }} />
                                                }
                                                {
                                                    note.split('\n').map((line, index) => {
                                                        return (
                                                            <span key={index} className="d-block text-justify">{line}</span>
                                                        )
                                                    })
                                                }

                                            </span>
                                        }
                                        <>
                                            {
                                                !(note && note != " ") &&
                                                <span className="d-flex justify-content-end" style={{ marginTop: "-30px" }}>
                                                    <Button title={!(role == "supervisor" || role == "administrator") ? "Supervisor permission required" : ""} color="primary" disabled={!(role == "supervisor" || role == "administrator")} onClick={() => { this.changeAddNotesModalShow(true) }}>Add Notes</Button>
                                                </span>
                                            }
                                        </>
                                    </>
                                }
                            </h3>
                            <h3>Job Documents :<span><a href={job.contract == "U2004" ? `https://sem64.sharepoint.com/SEMConnect/JobBagCollection/${job.jobNumber}` : ` https://sem64.sharepoint.com/SEMConnect/JobBagU2023/${job.jobNumber}`} target="_blank">View</a></span></h3>
                        </div>
                    </div>
                }
                {
                    job && job.isCustomJob && job.jobNumber == 0 &&
                    <div className="side-pop float-right">
                        <div className="side-pop-header position-relative" style={{ backgroundColor: color }}>
                            <span className="close-pop" style={{ top: "16px" }}><img onClick={this.props.toggle} src="/media/close-pop.png" alt="" /></span>
                            <div className="row">
                                <div className="col-8 text-left">
                                    <h1>Job Title: {job.name}</h1>
                                    {/* <p>Custom Job</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="side-pop-body">
                            {
                                job.scheduledStartDate &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="preCon-card d-flex">
                                            <p>The job has been scheduled from <strong>{moment.utc(job.scheduledStartDate).local().format("Do MMMM YYYY hh:mm a")}</strong> till <strong>{moment.utc(job.scheduledEndDate).local().format("Do MMMM YYYY hh:mm a")}</strong></p>

                                            {
                                                job.scheduledStartDate && (role == "supervisor" || role == "administrator") &&
                                                <div className="col-4 d-flex align-items-center justify-content-end">
                                                    <a className="edit mr-2" onClick={(e) => { e.preventDefault(); this.toggleEditCustomJob(true) }} href="">Edit</a>
                                                    <a onClick={(e) => { e.preventDefault(); this.toggleCustomJobDeleteModal(true) }} href="">Remove</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <h3>Customer Notified  :<span>
                                {
                                    (role == "supervisor" || role == "administrator") ?
                                        <Input type="select" name="customerNotified" id="customerNotified" value={this.state.customerNotified} onChange={this.changeCustomerNotified}>
                                            <option>No</option>
                                            <option>Spoken to customer</option>
                                            <option>Left a message</option>
                                        </Input> :
                                        <span>{this.state.customerNotified || "No"}</span>
                                }
                            </span></h3>
                            <h3>DBYD Date  :
                            {
                                    (role == "supervisor" || role == "administrator") ?
                                        <DatePicker
                                            {...datePickerProp}
                                        /> :
                                        <span>{this.state.dbydDate ? moment.utc(this.state.dbydDate).local().format("Do MMMM YYYY") : "Not Saved"}</span>
                                }
                            </h3>
                            <h3>Foreman  :<span>{job.foreman || "No Foreman Assigned"}</span></h3>
                            <h3>Crew Assigned  :
                                {
                                    job.crew &&
                                    <div className="d-flex align-items-center crew-box">
                                        <span style={{ backgroundColor: job.crewColour }}>
                                            {job.crew}
                                        </span>
                                    </div>
                                }
                                {
                                    !job.crew &&
                                    <span>No Crew Assigned</span>
                                }
                            </h3>
                            <h3 className="d-block">Notes  :
                                {
                                    <>
                                        {
                                            note &&
                                            <span>
                                                {
                                                    (role == "supervisor" || role == "administrator") &&
                                                    <img src="/media/edit.png" width="13" className="ml-2" alt="" style={{ cursor: "pointer" }} onClick={() => { this.changeAddNotesModalShow(true) }} />
                                                }
                                                {
                                                    note.split('\n').map((line, index) => {
                                                        return (
                                                            <span key={index} className="d-block text-justify">{line}</span>
                                                        )
                                                    })
                                                }

                                            </span>
                                        }
                                        <>
                                            {
                                                !(note && note != " ") &&
                                                <span className="d-flex justify-content-end" style={{ marginTop: "-30px" }}>
                                                    <Button title={!(role == "supervisor" || role == "administrator") ? "Supervisor permission required" : ""} color="primary" disabled={!(role == "supervisor" || role == "administrator")} onClick={() => { this.changeAddNotesModalShow(true) }}>Add Notes</Button>
                                                </span>
                                            }
                                        </>
                                    </>
                                }
                            </h3>
                        </div>
                    </div>

                }
                <DeleteJob job={job} modal={this.state.removeConfirmationModal} toggle={this.toggleRemoveConfirmationModal} action={this.jobDelete} disabled={this.state.isRemovalButton} />
                <ScheduleJob setAlert={this.props.setAlert} closeAll={this.closeAll} modal={jobAssignModal} edit={this.state.edit} toggle={this.toggleJobAssignModal} jobDetails={this.props.jobDetails.job} startDate={job.scheduledStartDate || new Date()} endDate={job.scheduledEndDate || new Date()} monthChange={this.props.monthChange} outerModalToggle={this.props.toggle} setRefreshJobList={this.props.setRefreshJobList} statusColors={this.props.statusColors} dateSelected={this.props.dateSelected} dateClicked={this.props.dateClicked} dayDetailsShow={this.props.dayDetailsShow} />
                {
                    this.state.innerJobDetailsShow &&
                    <JobDetails jobColors={this.props.jobColors} monthSelected={this.props.monthSelected} toggleList={toggleList} toggle={() => { this.innerJobDetailsShowChange(false) }} jobDetails={this.state.innerJobDetails} monthChange={this.props.monthChange} setRefreshJobList={this.props.setRefreshJobList} setAlert={this.props.setAlert} statusColors={this.props.statusColors} />

                }
                <AddNotes toggle={this.changeAddNotesModalShow} modal={this.state.addNotesModalShow} notes={note} submit={this.noteEditSubmit} disabled={this.state.noteSubmitDisabled} />
                <ScheduleCustomJob startDate={job.scheduledStartDate} endDate={job.scheduledEndDate} crewSelected={job.crewID} note={job.note} foremanSelected={job.formanID} jobTitle={job.isCustomJob ? job.name : ""} modal={this.state.editCustomJobModalShow} toggle={() => this.toggleEditCustomJob(false)} id={job.id} monthChange={this.props.monthChange} monthSelected={this.props.monthSelected} outerModalToggle={this.props.toggle} setRefreshJobList={this.props.setRefreshJobList} setAlert={this.props.setAlert} dateSelected={this.props.dateSelected} dateClicked={this.props.dateClicked} dayDetailsShow={this.props.dayDetailsShow} />
                <ConfirmationModal isModal={this.state.customJobDeleteModal} toggleModal={() => this.toggleCustomJobDeleteModal(false)} headerMessage={`Delete Custom Job`} message={`Are you sure you want to delete ${job.title}?`} job={job} disabled={this.state.disabled} action={this.submitDeleteCustomJob} />
            </div>
        )
    }
}

export default JobDetails;