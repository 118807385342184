import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { login } from '../../../api/user';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            alertShow: false,
            isLoginButtonDisabled: false,
        }
    }

    onInputChange = (val, key) => {
        this.setState({
            [key]: val,
        })
    }

    toggleAlert = () => {
        this.setState((prevState) => ({
            alertShow: !prevState.alertShow,
        }));
    }

    setAlert = () => {
        this.toggleAlert();
        setTimeout(this.toggleAlert, 3000);
    }

    toggleIsLoginButtonDisabled = () => {
        this.setState((prevState) => ({
            isLoginButtonDisabled: !prevState.isLoginButtonDisabled,
        }))
    }

    onSubmitLogin = (e) => {
        this.toggleIsLoginButtonDisabled()
        e.preventDefault();
        let body = {
            email: this.state.email.trim(),
            password: this.state.password.trim()
        }
        login(body).then((data) => {
            let localStorageItem = {}
            localStorageItem["loggedIn"] = true;
            localStorageItem["name"] = data.name;
            localStorageItem["email"] = data.email;
            localStorageItem["role"] = data.role;
            localStorage.setItem("schedulingApp", JSON.stringify(localStorageItem))
            this.props.history.push('/home');
            this.toggleIsLoginButtonDisabled()
        }).catch((e) => {
            this.setAlert();
            this.toggleIsLoginButtonDisabled()
        })
    }
    render() {
        const {
            email,
            password } = this.state;
        return (
            // <Form onSubmit={this.onSubmitLogin}>
            //     <FormGroup>
            //         <Label for="userEmail">Email</Label>
            //         <Input value={email} onChange={(e) => this.onInputChange(e.target.value,'email')} type="email" name="email" id="userEmail" placeholder="Enter email" />
            //     </FormGroup>
            //     <FormGroup>
            //         <Label for="userPassword">Password</Label>
            //         <Input type="password" value={password} onChange={(e) => this.onInputChange(e.target.value,'password')} name="password" id="userPassword" placeholder="Enter password" />
            //     </FormGroup>
            //     <Button>Submit</Button>
            //     <Alert color="danger" isOpen={this.state.alertShow} toggle={this.toggleAlert}>
            //         I am an alert and I can be dismissed!
            //     </Alert>
            // </Form>
            <div className="login-body">
                <div className="container-fluid">
                    <div className="login-box">
                        <div className="login position-absolute">
                            <div className="login-head">
                                <div>
                                    <h1>Login</h1>
                                    <p>Login using your email address</p>
                                </div>
                                <div><img src="/media/SEM_Group_logo.png" alt="" /></div>
                            </div>
                            <Form className="login-container" onSubmit={this.onSubmitLogin}>
                                <div>
                                    <label htmlFor="">Email Address
                                        <input type="text" placeholder="Enter your email" value={email} onChange={(e) => this.onInputChange(e.target.value, 'email')} />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="">Password
                                        <input type="password" placeholder="Enter your password" value={password} onChange={(e) => this.onInputChange(e.target.value, 'password')} />
                                    </label>
                                </div>
                                <div>
                                    <Button type="submit" disabled={this.state.isLoginButtonDisabled}>Log In</Button>
                                </div>
                            </Form>
                            <Alert style={{ width: "100%", display: "inline-block", position: 'absolute', left: '0', top: '-60px' }} color="danger" isOpen={this.state.alertShow} toggle={this.toggleAlert}>
                                Incorrect username or password
                            </Alert>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;