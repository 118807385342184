import React, { Fragment } from 'react';
import { Label, Input, FormGroup, Button, Card, CardHeader, CardBody, CardTitle, CardText, Spinner } from 'reactstrap';
import moment from 'moment-timezone';
import './Sidepanel.css';
import { listJobTypes, listJobs } from '../../../../api/jobs';
import ScheduleJob from '../Calendar/ScheduleJob';
import { listStatusColors, getCrewList, getForemanList } from '../../../../api/masterData';
import ScheduleCustomJob from '../customJob/ScheduleCustomJob';
import { runCloseJobs } from "../Functions"

class Sidepanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // jobTypes: [],
            unscheduledJobFilter: "",
            unscheduledJobs: {},
            moreFilters: false,
            statusColors: [],
            loading: false,
            showAddCustomJobModal: false,
            foremen: [],
            crews: [],
        }
        this.jobs = [];
        this.page = 0;
        this.lastPage = false;
    }

    componentDidMount() {
        // this.getJobTypes();
        this.jobSearch();
        listStatusColors().then((response) => {
            this.setState({
                statusColors: response,
            })
        })
        getCrewList().then((response) => {
            this.setState({
                crews: response,
            })
        })
        getForemanList().then((response) => {
            this.setState({
                foremen: response,
            })
        })
        let element = document.getElementById('job-list-box');
        element.addEventListener('scroll', (event) => { this.trackScrolling(event, element) });
    }

    componentWillUnmount = () => {
        let element = document.getElementById('job-list-box');
        element.removeEventListener('scroll', this.trackScrolling)
    }
    trackScrolling = (e, element) => {
        const elmnt = e.target;
        if (elmnt.scrollHeight - elmnt.scrollTop === elmnt.clientHeight && !this.lastPage) {
            this.jobSearch();
            element.removeEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.refreshJobList != prevProps.refreshJobList && this.props.refreshJobList === true) {
            this.setState({
                unscheduledJobs: {},
            }, () => {
                this.jobs = [];
                this.page = 0;
                this.lastPage = false;
                this.jobSearch();
                this.props.setRefreshJobList(false);
            })
        }
    }

    jobSearch = () => {
        if (this.page == -1) {
            return
        }
        const currentPage = this.page
        let params = {
            searchValue: this.state.unscheduledJobFilter,
            page: this.page + 1,
        }
        this.page = -1
        this.setState({
            loading: true,
        }, () => {
            listJobs(params).then((response) => {
                // if (this.page == response.page) {
                //     console.log("this.page ", this.page, " response.page ", response.page)
                //     this.setState({
                //         loading: false
                //     })
                //     return
                // }
                if (response.data == null || response.data.length === 0) {
                    this.setState({
                        loading: false,
                    })
                    return
                }
                this.lastPage = false;
                if (currentPage == 0) {
                    this.jobs = []
                }
                this.jobs.push(...response.data);
                let updatedUnscheduledJobs = this.generateJobCards();
                this.setState({
                    unscheduledJobs: updatedUnscheduledJobs,
                    loading: false,
                }, () => {
                    this.page = response.page;
                    if (Object.keys(this.state.unscheduledJobs).length < 10) {
                        this.jobSearch();
                    }
                })
            }).catch((error) => {
                this.page = currentPage
                this.setState({
                    loading: false,
                })
            })
        })
    }

    generateJobCards = () => {
        let unscheduledJobs = {}
        this.jobs.forEach(job => {
            let key = `${job.jobNumber}-${job.contract}`
            if (!unscheduledJobs[key]) {
                unscheduledJobs[key] = job;
                unscheduledJobs[key].workOrders = [{
                    jobTypeCode: job.jobTypeCode,
                    workOrderNumber: job.workOrderNumber,
                }];
                delete unscheduledJobs.jobTypeCode;
                delete unscheduledJobs.workOrderNumber;
            } else {
                unscheduledJobs[key].workOrders.push({
                    jobTypeCode: job.jobTypeCode,
                    workOrderNumber: job.workOrderNumber,
                })
            }
        })
        return unscheduledJobs;
    }

    jobFilterValueChanged = (unscheduledJobFilter) => {
        this.jobs = [];
        this.page = 0;
        this.lastPage = false;
        this.setState({
            unscheduledJobFilter,
            unscheduledJobs: {},
        }, () => {
            this.jobSearch();
        })
    }

    // getJobTypes = () => {
    //     listJobTypes().then((response) => {
    //         this.setState({
    //             jobTypes: response.data,
    //         })
    //     }).catch((error) => {
    //         console.log("unable to list job types")
    //     })
    // }

    filtersShowSet = (value) => {
        this.setState({
            moreFilters: value,
        })

    }

    // unscheduledJobCardClicked = (job) => {
    // this.loadUnassignedJobValues(job);
    // this.toggleJobAssignModal();
    // }

    // loadUnassignedJobValues = (job) => {
    //     this.setState({
    //         unassignedJobValues: job,
    //     })
    // }

    // toggleJobAssignModal = () => {
    //     this.setState((prevState) => ({
    //         jobAssignModal: !prevState.jobAssignModal,
    //     }))
    // }
    toggleCustomJobModal = (val) => {
        this.setState({
            showAddCustomJobModal: val,
        })
    }

    returnBorderColor = (statusId) => {
        let backgroundColor = "";
        this.state.statusColors.map((status) => {
            if (status.statusID == statusId) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }
    render() {
        const {
            unscheduledJobFilter,
            unscheduledJobs,
            unassignedJobValues,
            jobAssignModal,
        } = this.state;
        let role = JSON.parse(localStorage.getItem("schedulingApp")).role;
        return (
            <div className="sidepanel" id="job-list-box">
                <div className="flter-by">
                    <h2>{this.props.customSearch ? "Search Custom Jobs" : "Search By"}
                        {
                            this.props.cardView &&
                            <span style={{ float: 'right', cursor: "pointer" }} onClick={() => { if (!this.props.loading) { this.props.backClicked() } }}>Back</span>
                        }
                        {
                            !this.props.cardView && !this.props.customSearch &&
                            <a style={{ float: 'right', cursor: "pointer" }} onClick={(e) => { e.preventDefault(); if (!this.props.loading) { this.props.customSearchClicked(); this.filtersShowSet(true); this.props.filterClicked(); } }} href="">Custom Job Search</a>
                        }
                    </h2>
                    <div className="filter-checkbox" style={{ height: this.state.moreFilters && "600px", transition: this.state.moreFilters && "height 1s" }}>
                        {
                            this.props.customSearch ?
                                <Fragment>
                                    <FormGroup>
                                        <Label for="jobTitle">Job Title</Label>
                                        <Input type="text" name="jobTitle" id="jobTitle" onChange={(e) => { this.props.valueChange("jobTitle", e.target.value) }} value={this.props.jobTitle} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="foreman">Foreman</Label>
                                        <Input type="select" name="foreman" id="foreman" onChange={(e) => { this.props.valueChange("foreman", e.target.value) }} value={this.props.foreman} >
                                            <option value="">Choose Foreman</option>
                                            {
                                                this.state.foremen.length > 0 &&
                                                this.state.foremen.map((foreman, index) => {
                                                    return (
                                                        <option key={index}>{foreman.foreman}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="crew">Crew</Label>
                                        <Input type="select" name="crew" id="crew" onChange={(e) => { this.props.valueChange("crewID", e.target.value) }} value={this.props.crewID}>
                                            <option value="">Choose Crew</option>
                                            {
                                                this.state.crews.length > 0 &&
                                                this.state.crews.map((crew) => {
                                                    return (
                                                        <option key={crew.id} value={crew.id}>{crew.crew}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Fragment> :
                                <Fragment>
                                    <FormGroup>
                                        <Label for="caseNumber">Case Number</Label>
                                        <Input type="text" name="caseNumber" id="caseNumber" onChange={(e) => { this.props.valueChange("caseNumber", e.target.value) }} value={this.props.caseNumber} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="jobNumber">Job Number</Label>
                                        <Input type="text" name="jobNumber" id="jobNumber" onChange={(e) => { this.props.valueChange("jobNumber", e.target.value) }} value={this.props.jobNumber} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="foreman">Foreman</Label>
                                        <Input type="select" name="foreman" id="foreman" onChange={(e) => { this.props.valueChange("foreman", e.target.value) }} value={this.props.foreman}>
                                            <option value="">Choose Foreman</option>
                                            {
                                                this.state.foremen.length > 0 &&
                                                this.state.foremen.map((foreman, index) => {
                                                    return (
                                                        <option key={index}>{foreman.foreman}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="zone">Zone</Label>
                                        <Input type="text" name="zone" id="zone" onChange={(e) => { this.props.valueChange("zone", e.target.value) }} value={this.props.zone} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="councilName">Council Code</Label>
                                        <Input type="text" name="councilName" id="councilName" onChange={(e) => { this.props.valueChange("councilName", e.target.value) }} value={this.props.councilName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="suburb">Suburb</Label>
                                        <Input type="text" name="suburb" id="suburb" onChange={(e) => { this.props.valueChange("suburb", e.target.value) }} value={this.props.suburb} />
                                    </FormGroup>
                                </Fragment>
                        }
                        <Button className="float-left" color="primary" onClick={() => { if (!this.props.loading) { this.props.filterClicked() } }}>Search</Button>
                        <a href="" className="close-filter" onClick={(e) => { e.preventDefault(); this.filtersShowSet(false); }}>Hide Filters</a>
                    </div>
                    <div className="row">
                        {
                            !this.state.moreFilters &&
                            <div className="col-12 d-flex justify-content-end">
                                <a href="" className="more-filter" onClick={(e) => { e.preventDefault(); this.filtersShowSet(true); }}>Show Filters</a>
                            </div>
                        }
                        {
                            !this.props.cardView &&
                            <div className="col-12 text-left job-list-left">
                                <h2>
                                    Job List
                            </h2>
                                {
                                    (role == "supervisor" || role == "administrator") &&
                                    <Button color="primary" className="col-12 mb-3 mt-1" onClick={() => this.toggleCustomJobModal(true)}>Add Custom Job</Button>

                                }
                                <Input type="text" name="jobListFiler" id="jobListFiler" placeholder="Search An Unscheduled Job" value={unscheduledJobFilter} onChange={(event) => { this.jobFilterValueChanged(event.target.value) }} />
                                {
                                    Object.keys(unscheduledJobs) && Object.keys(unscheduledJobs).length > 0 &&
                                    Object.keys(unscheduledJobs).sort((a, b) => {
                                        let dateA = new Date(unscheduledJobs[a].subCompletionDueDate), dateB = new Date(unscheduledJobs[b].subCompletionDueDate);
                                        return dateB - dateA;
                                    }).map((job) => {
                                        return (
                                            <Card style={{ marginTop: "10px", cursor: "pointer", borderLeftStyle: 'solid', borderLeftWidth: '2px', borderLeftColor: this.returnBorderColor(unscheduledJobs[job].statusID) }} draggable={(role == "supervisor" || role == "administrator")} onDragStart={() => { this.props.jobDragged(unscheduledJobs[job]) }} onClick={() => { runCloseJobs(); this.props.jobCardClicked(unscheduledJobs[job], false) }} key={job}>
                                                <CardBody>
                                                    <CardTitle>SEM Job Number : {unscheduledJobs[job].jobNumber} [ {unscheduledJobs[job].contract} ]
                                                        {
                                                            unscheduledJobs[job].jobActive != "true" &&
                                                            <span className="inactive">Inactive</span>
                                                        }
                                                    </CardTitle>
                                                    <CardText>
                                                        <div>Job Description : {unscheduledJobs[job].jobDescription}</div>
                                                        <div>Practical Completion Actual Date : {unscheduledJobs[job].pracCompletionActDate || "Not Saved"}</div>
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        )
                                    })
                                }
                                {
                                    !unscheduledJobs || Object.keys(unscheduledJobs).length < 1 && !this.state.loading &&
                                    <div className="d-flex align-items-center justify-content-center" >
                                        <div>
                                            <p style={{ fontSize: '25px', padding: '10px 0' }}>No results found</p>
                                        </div>
                                        {/* <p style={{ fontSize: '12px', padding: '10px 0' }}>Or the job you are searching for is already scheduled</p> */}
                                    </div>
                                }
                                {
                                    this.state.loading &&
                                    <div className="text-center my-5">
                                        <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                                    </div>
                                }
                                {

                                }
                            </div>
                        }
                    </div>
                </div>
                <ScheduleCustomJob modal={this.state.showAddCustomJobModal} toggle={() => this.toggleCustomJobModal(false)} monthChange={this.props.monthChange} monthSelected={this.props.monthSelected} />
            </div >
        )
    }
}

export default Sidepanel;