import fetch from './handler';

export const listJobs = (params) => {
  return fetch({
    url: "/jobs",
    method: "GET",
    params,
  })
}

export const listScheduledJobs = (params) => {
  return fetch({
    url: "/jobs/scheduled",
    method: "GET",
    params,
  })
}

export const listAllJobs = (params) => {
  return fetch({
    url: "/jobs/search",
    method: "GET",
    params,
  })
}

export const listJobTypes = () => {
  return fetch({
    url: "/jobs/types",
    method: "GET",
  })
}

export const scheduleJob = (body) => {
  return fetch({
    url: "/job",
    method: "POST",
    body,
  })
}

export const rescheduleJob = (id, body) => {
  return fetch({
    url: `/job/${id}`,
    method: "POST",
    body,
  })
}

export const deleteJob = (body) => {
  let id = body.id.toString();
  delete body.id;
  return fetch({
    url: '/job/delete/' + id,
    method: "POST",
    body,
  })
}

export const addNotes = (body) => {
  return fetch({
    url: '/job/note',
    method: 'POST',
    body,
  })
}

export const getNotes = (params) => {
  return fetch({
    url: '/job/note',
    method: 'GET',
    params,
  })
}

export const addCustomJob = (body) => {
  return fetch({
    url: '/customjob',
    method: 'POST',
    body,
  })
}

export const editCustomJob = (id, body) => {
  return fetch({
    url: `/customjob/${id}`,
    method: 'POST',
    body,
  })
}

export const searchCustomJob = (params) => {
  return fetch({
    url: `/customjob/search`,
    method: 'GET',
    params,
  })
}

export const deleteCustomJob = (id) => {
  return fetch({
    url: `/customjob/delete/${id}`,
    method: `POST`,
  })
}
