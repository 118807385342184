import React from 'react';
import { Table, Button } from 'reactstrap';
import ColorPickerModal from './ColorPickerModal';
import { listJobColors, listStatusColors, updateJobColors, updateStatusColors } from '../../../../api/masterData';

class ColorCodes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            status: [],
            disabled: false,
        }
    }

    componentDidMount = () => {
        this.getJobList();
        this.getStatusList();
    }

    getJobList = () => {
        this.setState({
            disabled: true,
        }, () => {
            listJobColors().then((response) => {
                this.setState({
                    jobs: response,
                })
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }
    getStatusList = () => {
        this.setState({
            disabled: true,
        }, () => {
            listStatusColors().then((response) => {
                this.setState({
                    status: response,
                })
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    onChangeColor = (key, index, color) => {
        let obj = this.state[key];
        obj[index].colour = color;
        this.setState({
            [key]: obj,
        })
    }

    jobColorsSubmitted = () => {
        let body = {
            data: this.state.jobs,
        }
        this.setState({
            disabled: true,
        }, () => {
            updateJobColors(body).catch((error) => {
                console.log("job colors updation failed")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    statusColorsSubmitted = () => {
        let body = {
            data: this.state.status,
        }
        this.setState({
            disabled: true,
        }, () => {
            updateStatusColors(body).catch((error) => {
                console.log("status colors updation failed")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    render() {
        const {
            jobs,
            status,
        } = this.state;
        let index = 1;
        return (
            <div className="master-tab-content">
                <div className="p-1">
                    <h2 className="mb-3 d-flex justify-content-between align-items-center">
                        Job Types
                    <Button onClick={this.getJobList}><img src="/media/refresh.png" alt="" /></Button>
                    </h2>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th className="text-left">#</th>
                                <th className="text-left">Job Type</th>
                                <th className="text-right">Color</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                jobs && jobs.length > 0 &&
                                jobs.map((job, index) => {
                                    return (
                                        <tr key={job.id}>
                                            <td scope="row" className="text-left">{index + 1}</td>
                                            <td className="text-left">{job.job}</td>
                                            <td className="text-right"><div className="color-box" id={"job-type" + index} style={{ backgroundColor: job.colour }}></div></td>
                                            <ColorPickerModal target={"job-type" + index} obj="jobs" index={index} colour={job.colour} onChangeColor={this.onChangeColor} />
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <div className="button-box text-left">
                        <Button color="primary" onClick={this.jobColorsSubmitted} disabled={this.state.disabled}>Submit</Button>
                    </div>
                </div>
                <div className="p-1">
                    <h2 className="mb-3 d-flex justify-content-between align-items-center">
                        Job Status
                    <Button onClick={this.getStatusList}><img src="/media/refresh.png" alt="" /></Button>
                    </h2>
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left">#</th>
                                <th className="text-left">Job Status</th>
                                <th className="text-right">Color</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                status && status.length > 0 &&
                                status.map((stat, index) => {
                                    return (
                                        <tr key={stat.statusID}>
                                            <td scope="row" className="text-left">{index + 1}</td>
                                            <td className="text-left">{stat.status}</td>
                                            <td className="text-right"><div className="color-box" id={"job-status" + index} style={{ backgroundColor: stat.colour }}></div></td>
                                            <ColorPickerModal target={"job-status" + index} obj={"status"} index={index} colour={stat.colour} onChangeColor={this.onChangeColor} />
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <div className="button-box text-left">
                        <Button color="primary" onClick={this.statusColorsSubmitted} disabled={this.state.disabled}>Submit</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ColorCodes;