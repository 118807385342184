import fetch from './handler';

export const login = (body) => {
  return fetch({
    method: 'POST',
    url: '/login',
    body,
  })
};

export const listUsers = (params) => {
  return fetch({
    method: 'GET',
    url: '/users',
    params,
  })
}

export const logout = () => {
  return fetch({
    method: 'POST',
    url: '/logout',
  })
}

export const addUser = (body) => {
  return fetch({
    method: 'POST',
    url: '/user',
    body
  })
}

export const updateUser = (body) => {
  return fetch({
    method: 'POST',
    url: '/user/' + body.id.toString(),
    body
  })
}

export const changePassword = (body) => {
  return fetch({
    method: 'POST',
    url: '/user/changepassword',
    body,
  })
}

export const resetPassword = (body, id) => {
  return fetch({
    method: 'POST',
    url: `/user/${id}/resetpassword`,
    body,
  })
}
