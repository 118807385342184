import React from 'react'
import { listStatusColors, listJobColors } from '../../../../../api/masterData';
import JobDetails from '.././JobDetails';
import moment from 'moment-timezone';
import './index.css';

class Agenda extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            statusColors: [],


        }
    }

    componentDidMount = () => {
        listStatusColors().then((response) => {
            this.setState({
                statusColors: response,
            })
        })

    }


    returnBorderColor = (statusId) => {
        let backgroundColor = "";
        this.state.statusColors.map((status) => {
            if (status.statusID == statusId) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }


    render() {
        const events = this.props.events.filter((event) => {
            return moment(event.start).isSameOrBefore(moment(this.props.monthSelected), 'month') && moment(event.end).isSameOrAfter(moment(this.props.monthSelected), 'month')
        })
        return (
            <div>
                <div className="agenda-table">
                    {
                        events && events.length > 0 ?
                            <table className="table text-left table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th width="10%">Start Date</th>
                                        <th width="10%">End Date</th>
                                        <th width="10%">Job Number/Title</th>
                                        <th width="20%">Description</th>
                                        <th width="10%">Date Received</th>
                                        <th width="10%">Sub Compl. Date</th>
                                        <th>Address</th>
                                        <th>Notes</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        events.sort((a, b) => {
                                            if (moment(a.start).isAfter(moment(b.start))) {
                                                return -1
                                            } else {
                                                return 0
                                            }
                                        }).map((job, index) => {
                                            return (
                                                <tr className="agenda-row" key={index} style={{ borderLeft: "15px", borderColor: this.returnBorderColor(job.statusId), borderStyle: "solid" }} onClick={() => { this.props.onSelectAgendaEvent(job) }}>
                                                    <td>{moment(job.start).format("Do MMM YYYY")}</td>
                                                    <td>{moment(job.end).format("Do MMM YYYY")}</td>
                                                    <td>{job.jobNumber ? `${job.jobNumber} [ ${job.contract} ]` : job.name}</td>
                                                    <td>{job.jobDescription} </td>
                                                    <td>{job.dateReceived ? moment(job.dateReceived).format("Do MMM YYYY") : ""}</td>
                                                    <td>{job.subCompletionDueDate ? moment(job.subCompletionDueDate).format("Do MMM YYYY") : ""}</td>
                                                    <td>{job.streetNumber ? `${job.streetNumber}, ${job.streetName}, ${job.suburb}` : ""}</td>
                                                    <td>{job.note}</td>
                                                </tr>
                                            )
                                        }
                                        )
                                    }
                                </tbody>
                            </table> :
                            <p className="mt-5" style={{ paddingRight: "117px" }}>No Jobs Found</p>

                    }

                </div>
            </div>
        )
    }
}

Agenda.title = (params) => {
    return `Agenda for ${moment(params).format("MMMM")}`
}

Agenda.navigate = (date, action) => {
    switch (action) {
        case "PREV":
            return moment(date).add(-1, 'month').toDate();

        case "NEXT":
            return moment(date).add(1, 'month').toDate();

        default:
            return date;
    }
}


export default Agenda;  