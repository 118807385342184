import React, { Fragment } from 'react';
import { getCrewList, getForemanList, listJobColors, updateCrewList } from '../../../../api/masterData';
import { Table, Button, Input } from "reactstrap";
import ColorPickerModal from '../ColorCodes/ColorPickerModal';
import ListForemen from './ListForemen';


class Crew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crews: [],
            foremen: [],
            editIndex: -1,
            editKey: "",
            foremanEditCrewName: "",
            foremanModal: false,
            crewForemen: [],
            disabled: false,
        }
    }
    componentDidMount() {
        this.listCrews();
        this.listForemen();
    }

    listCrews = () => {
        this.setState({
            disabled: true,
        }, () => {
            getCrewList().then((response) => {
                this.setState({
                    crews: response,
                })
            }).catch((error) => {
                console.log("Couldn't load crew list")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    listForemen = () => {
        this.setState({
            disabled: true,
        }, () => {
            getForemanList().then((response) => {
                this.setState({
                    foremen: response,
                })
            }).catch((error) => {
                console.log("Couldn't load foreman list")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })
    }

    onChangeColor = (key, index, color) => {
        let obj = this.state[key];
        obj[index].colour = color;
        this.setState({
            [key]: obj,
        })
    }

    setEditIndex = (index, key) => {
        this.setState({
            editIndex: index,
            editKey: key,
        })
    }

    nameChange = (name, index) => {
        let crews = this.state.crews;
        crews[index].crew = name;
        this.setState({
            crews,
        })
    }

    changeForemanModal = (value) => {
        this.setState({
            foremanModal: value,
        })
    }

    viewForemenClicked = (foremen, editIndex, foremanEditCrewName) => {
        let forem = [];
        if (foremen) {
            forem = foremen;
        }
        this.setState({
            crewForemen: forem,
            foremanEditCrewName,
        });
        this.setEditIndex(editIndex, "foreman");
        this.changeForemanModal(true);
    }

    foremanEditSubmit = (foremen) => {
        let crews = this.state.crews;
        crews[this.state.editIndex].foremen = foremen;
        this.setState({
            crews,
            crewForemen: [],
            editIndex: -1,
        });
        this.changeForemanModal(false);
    }

    submitChanges = () => {
        let body = {
            data: this.state.crews,
        }
        this.setState({
            disabled: true,
        }, () => {
            updateCrewList(body).then(() => {
                this.listCrews();
            }).catch((error) => {
                console.log("Error while updating crew list")
            }).finally(() => {
                this.setState({
                    disabled: false,
                })
            })
        })

    }

    render() {
        const {
            crews,
        } = this.state;
        return (
            <div className="master-tab-content">
                <h2 className="mb-3 d-flex justify-content-between align-items-center">Crew
                <Button disabled={this.state.disabled} onClick={this.getJobList}><img src="/media/refresh.png" alt="" onClick={this.listCrews} /></Button>
                </h2>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-left">#</th>
                            <th className="text-left">Crew</th>
                            <th className="text-left">Foremans</th>
                            <th className="text-right">Color</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            crews && crews.length > 0 &&
                            crews.map((crew, index) => {
                                let foremanCount = crew.foremen ? crew.foremen.length : 0;
                                return (
                                    <tr key={crew.id}>
                                        <td scope="row" className="text-left">{index + 1}</td>
                                        {
                                            this.state.editIndex === index && this.state.editKey === "name" &&
                                            <td className="text-left">
                                                <Input value={crew.crew} onChange={(e) => { this.nameChange(e.target.value, index) }} />
                                            </td>
                                        }
                                        {
                                            (this.state.editIndex !== index || this.state.editKey !== "name") &&
                                            <td onClick={() => { this.setEditIndex(index, "name") }} className="text-left">{crew.crew}<img src="/media/edit.png" width="13" className="ml-2" alt="" /></td>
                                        }
                                        <td onClick={() => { this.viewForemenClicked(crew.foremen, index, crew.crew) }} className="text-left">
                                            {foremanCount === 0 ? "No Foreman assigned" : foremanCount === 1 ? crew.foremen[0].foreman : foremanCount === 2 ? `${crew.foremen[0].foreman} and ${crew.foremen[1].foreman}` : `${crew.foremen[0].foreman} and ${crew.foremen.length - 1} others`}
                                            <img src="/media/edit.png" width="13" className="ml-2" alt="" />
                                        </td>
                                        <td className="text-right"><div className="color-box" style={{ backgroundColor: crew.colour }} id={"crew" + index}></div></td>
                                        <ColorPickerModal target={"crew" + index} obj="crews" index={index} colour={crew.colour} onChangeColor={this.onChangeColor} />
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table >
                <div className="button-box text-left">
                    <Button disabled={this.state.disabled} color="primary" onClick={this.submitChanges}>Submit</Button>
                </div>
                <ListForemen modal={this.state.foremanModal} toggle={() => { this.changeForemanModal(false) }} selected={this.state.crewForemen} options={this.state.foremen} crew={this.state.foremanEditCrewName} submit={this.foremanEditSubmit} />
            </div >
        )
    }
}

export default Crew;