import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MultiSelect from "@khanacademy/react-multi-select";

class ListForemen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedForemen: [],
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.modal != this.props.modal) {
            let selectedForemen = [];
            this.props.selected.forEach((select) => {
                selectedForemen.push(select.id);
            });
            this.setState({
                selectedForemen,
            })
        }
    }

    submitClicked = () => {
        let foremen = this.props.options.filter((opt) => {
            delete opt.contract;
            delete opt.SSMATimestamp;
            return this.state.selectedForemen.includes(opt.id)
        }
        )
        this.props.submit(foremen)
    }

    render() {
        let fullOptions = [];
        this.props.options.forEach(opt => {
            fullOptions.push({
                value: opt.id,
                label: opt.foreman,
            })
        });
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Manage Foremen</ModalHeader>
                <ModalBody>
                    <p>Add or remove foremen to {this.props.crew} </p>
                    <MultiSelect
                        options={fullOptions}
                        selected={this.state.selectedForemen}
                        onSelectedChanged={selectedForemen => this.setState({ selectedForemen })}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitClicked}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ListForemen;