import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

const isLoggedIn = (ComposedComponent, path) => {
    return class AuthCheck extends Component {
        constructor(props) {
            super(props);

            this.state = {
                cookiePresent: false,
                checkComplete: false,
            }
        }
        componentDidMount = () => {
            this.checkCookies();
        }

        checkCookies = () => {
            let localStorageItem = JSON.parse(localStorage.getItem("schedulingApp"))
            if (localStorageItem && localStorageItem.loggedIn) {
                this.setState({
                    cookiePresent: true,
                    checkComplete: true,
                })
            } else {
                this.setState({
                    cookiePresent: false,
                    checkComplete: true,
                })
            }
        }
        render() {
            const { cookiePresent, checkComplete } = this.state;
            if (checkComplete) {
                if (path === '/login') {
                    if (cookiePresent) {
                        return <Redirect to="/home" />
                    } else {
                        return <ComposedComponent {...this.props} />
                    }
                }
                if (cookiePresent) {
                    return <ComposedComponent {...this.props} />;
                }
                return <Redirect to="/login" />
            } else {
                return null;
            }
        }
    }

}

export default isLoggedIn;