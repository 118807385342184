import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from '../commonComponents/Navbar';
import MasterData from '../pages/MasterData';
const UserManagement = React.lazy(() => import('../pages/UserManagement'));
const Home = React.lazy(() => import('../pages/Home'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
// import Home from '../pages/Home';                                    //for testing
// import NotFound from '../pages/NotFound';

class Routing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sideMenuShow: false,
        }
    }

    sideMenuToggle = () => {
        this.setState((prevState) => ({
            sideMenuShow: !prevState.sideMenuShow,
        }))
    }
    render() {
        return (
            <Fragment>
                <div className={this.state.sideMenuShow ? "container-fluid menu-opened" : "container-fluid"}>
                    <Route path="/" render={(props) => <Navbar {...props} sideMenuToggle={this.sideMenuToggle} />} />
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/user" component={UserManagement} />
                            <Route exact path="/master-data" component={MasterData} />
                            <Redirect exact from='/' to='/home' />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </Suspense>
                </div>
            </Fragment >
        )
    }
}

export default Routing;