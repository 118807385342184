import React, { Fragment } from "react";
import moment from "moment-timezone";
import './index.css';

class DayDetails extends React.Component {
    statusColorGetter = (statusId) => {
        let backgroundColor = "";
        this.props.statusColors.map((status) => {
            if (status.statusID == statusId) {
                backgroundColor = status.colour;
            }
        })
        return backgroundColor;
    }

    barClicked = (event) => {
        this.props.jobCardClicked(event, true);
    }

    render() {
        return (
            <div className="side-pop-bg day-details">
                <div className="side-pop float-right">
                    <div className="side-pop-header position-relative">
                        <span className="close-pop"><img src="/media/close-pop.png" alt="" onClick={() => { this.props.toggle(false) }} /></span>
                        <div className="row">
                            <div className="col-8 text-left">
                                <h1>Daily Jobs</h1>
                                <p>Jobs on {moment(this.props.dateSelected).format("Do MMM YYYY")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="side-pop-body day-details-pop-body">
                        {
                            this.props.eventsOnDate.length > 0 &&
                            <div className="bg-light p-2 text-center">
                                Jobs assigned for the day
                            {
                                    this.props.eventsOnDate.map((event, index) => {
                                        return (
                                            <div key={index} className="event-bar m-2 p-2" style={{ borderLeftColor: this.statusColorGetter(event.statusId), borderLeftWidth: "6px", borderLeftStyle: "solid" }} onClick={() => { this.barClicked(event) }}>
                                                {
                                                    <Fragment>
                                                        {
                                                            event.isCustomJob ?
                                                                <strong>{`Job Title: ${event.name}`}</strong>
                                                                :
                                                                <div>
                                                                    <strong>{`SEM Job Number: ${event.jobNumber} [ ${event.contract} ]`}</strong>
                                                                    <p>{`Job Description: ${event.jobDescription}`}</p>
                                                                </div>
                                                        }
                                                    </Fragment>
                                                }
                                                <div className="date-block">
                                                    <p>{`Start: ${moment.utc(event.scheduledStartDate).local().format("Do MMM YYYY hh:mm a")}`}</p>
                                                    <p>{`End: ${moment.utc(event.scheduledEndDate).local().format("Do MMM YYYY hh:mm a")}`}</p>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.props.eventsOnDate.length === 0 &&
                            <div className="no-data-block py-20">
                                No events are added for this date
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DayDetails;