import React from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { CompactPicker } from 'react-color';

class ColorPickerModal extends React.Component {
    render() {
        return (
            <UncontrolledPopover trigger="legacy" placement="right" target={this.props.target}>
                <PopoverHeader>Choose Color</PopoverHeader>
                <PopoverBody>
                    <p>Choose a color from the default colors or enter a hex/RGB value</p>
                    <CompactPicker onChangeComplete={(color) => { this.props.onChangeColor(this.props.obj, this.props.index, color.hex) }} color={this.props.colour} />
                </PopoverBody>
            </UncontrolledPopover>
        )
    }
}

export default ColorPickerModal;