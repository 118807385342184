import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Alert } from 'reactstrap';
import './index.css';

class AddNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            showAlert: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.setState({
                notes: this.props.notes
            })
        }
    }

    changeNotes = (event) => {
        this.setState({
            notes: event.target.value,
        })
    }

    submit = () => {
        if (!this.state.notes || !this.state.notes.trim()) {
            this.setState({
                showAlert: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showAlert: false,
                    })
                }, 1500)
            })
            return;
        }
        this.props.submit(this.state.notes)
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => { this.props.toggle(false) }}>
                <ModalHeader toggle={() => { this.props.toggle(false) }}>Add Notes To Job</ModalHeader>
                <ModalBody>
                    <Alert color="danger" isOpen={this.state.showAlert} toggle={() => { this.setState({ showAlert: false }) }}>Enter text to submit</Alert>
                    <Label for="notes">Notes</Label>
                    <Input type="textarea" name="text" id="notes" className="text-area" value={this.state.notes} onChange={this.changeNotes} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit} disabled={this.props.disabled}>Submit</Button>{' '}
                    <Button color="secondary" onClick={() => { this.props.toggle(false) }}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddNotes;