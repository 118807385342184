import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import isLoggedIn from '../HOC/isLoggedIn';
import Routing from '../Routing';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={isLoggedIn(Login, "/login")} />
          <Route path="*" component={isLoggedIn(Routing)} />
          {/* <Route path="/login" component={Login} /> */}
          {/* <Route path="*" component={Routing} /> */}
        </Switch>
      </div>
    );
  }
}
export default App;
