let apiRoot;

if (process.env.NODE_ENV === "production") {
  apiRoot = "**APIROOT**";
} else if (process.env.NODE_ENV === "development") {
  // apiRoot = 'https://172.17.7.18:9443';
  // apiRoot = "http://172.25.6.1:9443";
  apiRoot = "http://localhost:9443";
  // apiRoot = "http://semapi.local:9443";
}

export const API_ROOT = apiRoot;
