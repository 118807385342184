import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Alert } from 'reactstrap';
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { getCrewList, getForemanList } from '../../../../../api/masterData';
import { addCustomJob, editCustomJob, searchCustomJob } from '../../../../../api/jobs';
import './index.css'

moment.locale('en')
momentLocalizer()

class ScheduleCustomJob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment().utc().format(),
            endDate: moment().utc().format(),
            crews: [],
            foremen: [],
            crewSelected: 0,
            foremanSelected: 0,
            note: "",
            jobTitle: "",
            alertShow: false,
            alertMessage: "",
            alertColor: "",
            disabled: false,
        }
    }
    componentDidMount = () => {
        getCrewList().then((response) => {
            this.setState({
                crews: response,
            })
        })
        getForemanList().then((response) => {
            this.setState({
                foremen: response,
            })
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.id && prevProps.modal != this.props.modal && this.props.modal) {
            let params = {
                id: this.props.id,
            }
            searchCustomJob(params).then((response) => {
                this.setState({
                    note: response.data[0].note,
                })
            }).catch((error) => {
                this.setState({
                    note: "",
                })
            })
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                crewSelected: this.props.crewSelected,
                foremanSelected: this.props.foremanSelected,
                jobTitle: this.props.jobTitle,
            })
        } else if (!this.props.id && this.props.modal != prevProps.modal && this.props.modal) {
            this.setState({
                startDate: moment().utc().format(),
                endDate: moment().utc().format(),
                crewSelected: 0,
                foremanSelected: 0,
                note: "",
                jobTitle: "",
            })
        }
    }

    toggleIsSubmitButtonDisabled = () => {
        this.setState((prevState) => ({
            disabled: !prevState.disabled,
        }))
    }

    changeStartDate = (date) => {
        if (!moment(date).isValid()) {
            this.setAlert("Invalid format entered for start date", "danger");
            return
        }
        this.setState({
            startDate: moment(date).utc().format(),
        }, () => {
            if (moment(this.state.startDate).isAfter(moment(this.state.endDate), "day")) {
                this.setState({
                    endDate: this.state.startDate,
                })
            }
        })
    }
    changeEndDate = (date) => {
        if (!moment(date).isValid()) {
            this.setAlert("Invalid format entered for end date", "danger");
            return
        }
        this.setState({
            endDate: moment(date).utc().format(),
        }, () => {
            if (moment(this.state.startDate).isAfter(moment(this.state.endDate), "day")) {
                this.setState({
                    startDate: this.state.endDate,
                })
            }
        })
    }

    changeCrew = (id) => {
        this.setState({
            crewSelected: parseInt(id),
        })
    }

    changeNote = (e) => {
        this.setState({
            note: e.target.value,
        })
    }

    changeForeman = (foreman) => {
        this.setState({
            foremanSelected: foreman,
        })
    }

    changeJobTitle = (e) => {
        this.setState({
            jobTitle: e.target.value,
        })
    }

    submit = () => {
        this.toggleIsSubmitButtonDisabled()
        if (this.state.foremanSelected == 0) {
            this.setAlert("Enter the foreman", "danger");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        let selectedForeman = {}
        selectedForeman = this.state.foremen.filter((foreman) => foreman.id == this.state.foremanSelected)[0]
        if (!selectedForeman) {
            this.setAlert("Enter the foreman", "danger");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        let foreman = {
            id: selectedForeman.id,
            foreman: selectedForeman.foreman,
        }
        let body = {
            scheduledStartDate: this.state.startDate,
            scheduledEndDate: this.state.endDate,
            note: this.state.note,
            jobTitle: this.state.jobTitle,
            crewID: this.state.crewSelected,
            foreman: JSON.stringify(foreman),
        }
        if (!body.crewID) {
            this.setAlert("Enter the crew", "danger");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (!body.jobTitle) {
            this.setAlert("Enter the title", "danger");
            this.toggleIsSubmitButtonDisabled();
            return;
        }
        if (this.props.id) {
            editCustomJob(this.props.id, body).then((response) => {
                this.props.monthChange(this.props.monthSelected, () => {
                    if (this.props.dayDetailsShow && this.props.dateSelected) {
                        this.props.dateClicked({ start: this.props.dateSelected })
                    }
                });
                this.props.toggle()
                if (this.props.outerModalToggle != null) {
                    this.props.outerModalToggle();
                }
                this.closeAlert();
                this.props.setAlert(`Job ${body.jobTitle} successfully scheduled`, "success")
                this.toggleIsSubmitButtonDisabled();
            }).catch((error) => {
                console.log("Error while scheduling job", error)
                this.setAlert("Could not schedule job. Please try again", "danger");
                this.toggleIsSubmitButtonDisabled()
            })
        } else {
            addCustomJob(body).then((response) => {
                this.props.monthChange(this.props.monthSelected);
                this.setAlert(`Job ${body.jobTitle} successfully scheduled`, "success");
                this.toggleIsSubmitButtonDisabled();
                setTimeout(() => {
                    this.props.toggle()
                }, 1000)
            }).catch((error) => {
                console.log("Error while scheduling job", error)
                this.setAlert("Could not schedule job. Please try again", "danger");
                this.toggleIsSubmitButtonDisabled()
            })
        }
    }

    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertShow: true,
            alertMessage,
            alertColor,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertShow: false,
                    alertMessage: "",
                    alertColor: "",
                })
            }, 1500)
        })
    }

    closeAlert = () => {
        this.setState({
            alertShow: false,
            alertMessage: "",
            alertColor: "",
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Create and Schedule a Custom Job</ModalHeader>
                <ModalBody>
                    <Alert isOpen={this.state.alertShow} toggle={this.closeAlert} color={this.state.alertColor}>{this.state.alertMessage}</Alert>
                    <Form>
                        <FormGroup>
                            <p>Title</p>
                            <Input placeholder="Enter the job title" value={this.state.jobTitle} onChange={this.changeJobTitle} />
                        </FormGroup>
                        <div>
                            <p>Start Date</p>
                            <DateTimePicker
                                value={new Date(this.state.startDate)}
                                onChange={(value) => this.changeStartDate(value)}
                                min={new Date()}
                            />
                        </div>
                        <div>
                            <p>End Date</p>
                            <DateTimePicker
                                value={new Date(this.state.endDate)}
                                onChange={(value) => this.changeEndDate(value)}
                                min={new Date()}
                            />
                        </div>
                        <div>
                            <p>Crew</p>
                            <Input type="select" name="crew" id="crew" rows="6" value={this.state.crewSelected} onChange={(e) => this.changeCrew(e.target.value)}>
                                <option>Choose a crew</option>
                                {
                                    this.state.crews && this.state.crews.length > 0 &&
                                    this.state.crews.map((crew) => {
                                        return (
                                            <option value={crew.id} key={crew.id}>{crew.crew}</option>
                                        )
                                    })
                                }
                            </Input>
                        </div>
                        <div>
                            <p>Foreman</p>
                            <Input type="select" name="foreman" id="foreman" rows="6" value={this.state.foremanSelected} onChange={(e) => this.changeForeman(e.target.value)}>
                                <option>Choose a foreman</option>
                                {
                                    this.state.foremen && this.state.foremen.length > 0 &&
                                    this.state.foremen.map((foreman) => {
                                        return (
                                            <option value={foreman.id} key={foreman.id}>{foreman.foreman}</option>
                                        )
                                    })
                                }
                            </Input>
                        </div>
                        <FormGroup>
                            <p>Notes</p>
                            <Input type="textarea" className="note-input" value={this.state.note} onChange={this.changeNote} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit} disabled={this.state.disabled}>Submit</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ScheduleCustomJob;