import fetch from './handler';

export const listJobColors = () => {
  return fetch({
    url: '/master/job',
    method: 'GET',
  })
}

export const updateJobColors = (body) => {
  return fetch({
    url: '/master/job',
    method: 'POST',
    body,
  })
}

export const listStatusColors = () => {
  return fetch({
    url: '/master/stage',
    method: 'GET',
  })
}

export const updateStatusColors = (body) => {
  return fetch({
    url: '/master/stage',
    method: 'POST',
    body,
  })
}

export const getCrewList = () => {
  return fetch({
    url: '/master/crew',
    method: 'GET',
  })
}

export const updateCrewList = (body) => {
  return fetch({
    url: '/master/crew',
    method: 'POST',
    body,
  })
}

export const getForemanList = () => {
  return fetch({
    url: '/master/foreman',
    method: 'GET',
  })
}
