import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert } from "reactstrap";

class ConfirmationModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isModal} toggle={this.props.toggleModal}>
                <ModalHeader toggle={this.props.toggleModal}>{this.props.headerMessage}</ModalHeader>
                <ModalBody>{this.props.message}</ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={this.props.isDisabled} onClick={() => { this.props.action(this.props.job) }}>Ok</Button>
                    <Button onClick={this.props.toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ConfirmationModal;